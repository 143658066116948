export const setBrands=(brands)=>{
    return{
        type:'BRANDS',
        payload:brands
    }
}
export const Login=()=>{
    return{
        type:'LOGIN'
    }
}
export const Logout=()=>{
    return{
        type:'LOGOUT'
    }
}
export const setSpinner=(payload)=>{
    return{
        type:'SPINNER',
        payload:payload
    }
}
export const setToken=(payload)=>{
    return{
        type:'SET_TOKEN',
        payload:payload
    }
}
export const setResults=(payload)=>{
    return{
        type:'SET_RESULTS',
        payload:payload
    }
}
export const setPhoneNumber=(payload)=>{
    return{
        type:'SET_PHONE_NUMBER',
        payload:payload
    }
}
export const setUserType=(payload)=>{
    return{
        type:'SET_USER_TYPE',
        payload:payload
    }
}
export const setSchemas=(payload)=>{
    return{
        type:'SET_SCHEMAS',
        payload:payload
    }
}
export const setCosetteCode=(payload)=>{
    return{
        type:'SET_COSETTE_CODE',
        payload:payload
    }
}
