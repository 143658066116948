import React, {useEffect, useState} from "react";
import {getSubmittedItem} from "../api/api";
import {useParams} from "react-router-dom";
import {useDispatch,useSelector} from "react-redux";
import { setSpinner} from "../redux/actions/actions";
import {
    getAsgItemReq,
    getTablesOfDb, 
} from "../api/api";
import CorrectQueryResults from "../components/CorrectQueryResults";
import YourQueryResults from "../components/YourQueryResults";



function SubmittedItem(props) {

    const {id,submitId} = useParams()
    const dispatch = useDispatch()
    const [message,setMessage]=useState('')
    const [state, setState] = useState(null); 
    const [results,setResults]=useState(null)
    const [submitted,setSubmitted]=useState(null)
    const [std_queries,setStdQueries]=useState({})
    const [assignment,setAssignment]=useState(null)
    const [sentToServer,setSentToServer]=useState(true)
    const Practice=(assignment&&assignment.type==='practice')
    const type=useSelector(state=>state.utils.userType)
    const student=type==='115'
    const [tempRes,setTempRes]=useState({})


    // GET THE ASSIGNMENT
    useEffect(()=>{
        dispatch(setSpinner(true))
        getAsgItemReq(id)
            .then(res=>{
                setAssignment(res.data.list)
                dispatch(setSpinner(false))
            })
            .catch(err=>{
                if (err.response){
                    setMessage(err.response.data.message)
                }
                dispatch(setSpinner(false))
            })
    },[])

    // ONLOAD, GET THE SUBMITTED ASSIGNMNET
    useEffect(() => {
        dispatch(setSpinner(true))
        getSubmittedItem(submitId)
            .then(res=>{
                setState(res.data)
                dispatch(setSpinner(false))
                setSubmitted(res.data)
                setResults(res.data.results)
                setTempRes(()=>res.data.difference)
            })
            .catch(err=>{
                dispatch(setSpinner(false))
            })
    }, []);




    return(
        <>
            {
                state && assignment?
                    <>
                        <div className="row">

                            {/* Student and Assignment Info */}
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-4">
                                        <h5 style={{fontFamily:"Nuinto-Bold"}} className="mb-2">Assignment name:</h5>
                                        <span style={{fontFamily:"Nuinto-Medium"}} className="text-secondary">
                                            {state.asg_name}
                                        </span>
                                    </div>

                                    <div className="col-md-4">
                                        <h5 style={{fontFamily:"Nuinto-Bold"}} className="mb-2">Student ID:</h5>
                                        <span style={{fontFamily:"Nuinto-Medium"}} className="text-secondary">
                                            {state.std_id}
                                        </span>
                                    </div>

                                    <div className="col-md-4">
                                        <h5 style={{fontFamily:"Nuinto-Bold"}} className="mb-2">Student name:</h5>
                                        <span style={{fontFamily:"Nuinto-Medium"}} className="text-secondary">
                                            {state.std_name}
                                        </span>
                                    </div>
                                </div>
                                <hr />
                            </div>
                            

                            {/*Answers*/}
                            <div className="col-md-4">
                                <h5 style={{fontFamily:"Nuinto-Bold"}} className="mb-3">Student's answers:</h5>
                                {
                                    Object.keys(Array.isArray(state.answers) ? state.answers[0]: state.answers).map((key,idx)=>(
                                        <div className="mb-2">
                                            <span key={idx}>
                                                {key}:
                                                <br/>
                                                <span style={{fontFamily:"Nuinto-Medium"}} className="text-secondary">{(Array.isArray(state.answers) ? state.answers[0]: state.answers) [key]}</span>
                                            </span>
                                            <br/>
                                        </div>
                                    ))
                                }
                            </div>

                            {/*Correct Answers*/}
                            <div className="col-md-4">
                                <h5 style={{fontFamily:"Nuinto-Bold"}} className="mb-3">Instructor's answers:</h5>
                                {
                                    (assignment.questions).map(({question,can_query},idx)=>(
                                        <div key={idx} className="mb-2">
                                            {question}:
                                            <br/>
                                                <span style={{fontFamily:"Nuinto-Medium"}} className="text-secondary">{can_query}</span>
                                            <br/>
                                        </div>
                                    ))
                                }
                            </div>

                            {/*Results*/}
                            <div className="col-md-4">
                                <h5 style={{fontFamily:"Nuinto-Bold"}} className="mb-3">Here are the results:</h5>
                                {
                                    Object.keys(Array.isArray(state.results) ? state.results[0]: state.results).map((key,idx)=>(
                                        <div className="mb-2">
                                            <span key={idx}>
                                                {key}:
                                                <br/>
                                                <span style={{fontFamily:"Nuinto-Medium"}} className="text-secondary">{(Array.isArray(state.results) ? state.results[0]: state.results) [key]}</span>
                                            </span>
                                            <br/>
                                        </div>
                                    ))
                                }
                            </div>

                        </div>
                    </>
                :
                    null
            }

            <div className="pb-3">
          
                <hr/>
                {
                    assignment?
                        <>
                            <div className="row">
                                {/* Assignment type*/}
                                <div className="col-md-4">
                                    <h5>Assignment Type:</h5>
                                    <div className="form-group mb-3">
                                        <p style={{fontFamily:'Nunito-Bold'}} className="text-danger">
                                            {Practice?'Practice':'Test'}
                                        </p>
                                    </div>
                                </div>
                                {/* Grading Type*/}
                                <div className="col-md-4">
                                {
                                    !Practice?
                                        <>
                                            <h5>Grading type:</h5>
                                            <div className="form-group mb-3">
                                                <p style={{fontFamily:'Nunito-Bold'}} className="text-danger">
                                                    {assignment.grading}
                                                </p>
                                            </div>
                                        </>
                                    :
                                        null
                                }
                                </div>
                            </div>


                            {/* Grades */}
                            {
                                submitted && assignment &&                       
                                <h5 style={{fontFamily:"Nunito-Bold",border:'1px solid lightgreen',fontSize:'11pt'}} className="mb-4 p-2 text-success">
                                    Student's Grade: {submitted.totalGrade}/{assignment.maxGrade}
                                </h5>
                            }


                            {/*Questions*/}
                            <div className="">
                                {
                                    assignment.questions.map(({question,can_query,points},idx)=>(
                                        <React.Fragment key={idx}>
                                            <div className="form-group">
                                                <span className="fw-bold">
                                                    {question}
                                                </span>
                                                {
                                                    !Practice?
                                                        <span className="fw-bold text-primary mb-0 ml-1">
                                                            <small>({points} points)</small>
                                                        </span>
                                                    :
                                                    null
                                                }
                                                <input 
                                                    name={question} 
                                                    value={ 
                                                        student? 
                                                            ( !submitted? std_queries[question] : submitted.answers.find(item=> Object.keys(item).some(i=>i===question))[question] )
                                                            // ( !submitted? std_queries[question] : submitted.find(item=> Object.keys(item)[0]===question)[question] )
                                                        :
                                                            (can_query)
                                                    } 
                                                    disabled={!student||(student&&submitted)} 
                                                    className="form-control mt-1" 
                                                    placeholder={'e.g. SELECT * FROM t1'}
                                                />
                                                {/*Input Error*/}
                                                {
                                                    results?
                                                        (Practice) || (!Practice && assignment.grading==='immediate' && sentToServer) || (submitted && !Practice && assignment.grading==='deferred') ?
                                                            <div className="Input-Err-Div">
                                                                {/*Query Compare Feedback*/}
                                                                <span className={`${results[question]&&results[question]==='EQ'?'valid-feedback':'invalid-feedback'} d-block`}>
                                                                    {
                                                                        results[question]&&
                                                                        results[question]==='EQ'?
                                                                            'Correct!':
                                                                                results[question]&&results[question]==='NEQ'?
                                                                                    'Incorrect!'
                                                                                :
                                                                                    results[question]
                                                                    }
                                                                </span>
                                                                {/*Correct Query*/}
                                                                <span style={{fontSize:12}}>
                                                                    {
                                                                        results[question] && results[question]!=='EQ'?
                                                                            <>
                                                                                correct query:
                                                                                <span className="text-success" style={{fontSize:12}}>
                                                                                    {' ' + can_query}
                                                                                </span>
                                                                            </>
                                                                        :
                                                                            ''
                                                                    }
                                                                </span>
                                                            </div>
                                                        :
                                                            null
                                                    :
                                                        null
                                                }
                                            </div>

                                            {
                                                tempRes&&tempRes[question]?
                                                    (Practice) || (!Practice && assignment.grading==='immediate' && sentToServer) || (!Practice && assignment.grading==='deferred' && submitted && assignment.graded) ?
                                                        <div className="mt-2">
                                                            {
                                                                tempRes&&
                                                                <CorrectQueryResults data={tempRes[question]} title='Correct Query Results' />
                                                            }
                                                            {
                                                                tempRes&&
                                                                <YourQueryResults data={tempRes[question]} title="Student's Query Results" />
                                                            }
                                                        </div>
                                                    :
                                                        null
                                                :
                                                    null
                                            }
                                        </React.Fragment>
                                    ))
                                }
                            </div>

                        

                        </>
                    :
                        null
                }
            </div>
        </>
    )
}


export default SubmittedItem;
