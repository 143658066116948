import React from "react"

function CreatedTablesList({tables}){

    return(
        <>
            {
                tables.length?
                    <div className="mt-5">
                        {/*Title*/}
                        <h5>
                            Your Tables:
                        </h5>
                        {
                            tables.map(({schema, name}, idx) => (
                                <div className="fw-bold" key={idx}>
                                    {name} {schema}
                                </div>
                            ))
                        }
                    </div>
                    :
                    null
            }
        </>
    )
}

export default CreatedTablesList;
