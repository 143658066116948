import React from "react"

function TestQueryResultsTable({data,title}){


    return(
        <div>
            {
                data?
                    <div className="mt-2">
                        <h6>
                            {title}
                        </h6>
                        <table className="table table-bordered">
                            {/*KEYS*/}
                            <thead>
                            <tr>
                                {Object.keys(data['test'][0]).map((key,idx)=>(
                                    <th key={idx}>
                                        {key + ' '}
                                    </th>
                                ))}
                            </tr>
                            </thead>
                            {/*ROWS*/}
                            <tbody>
                            {
                                (data.test).map((row,idy)=>(
                                    <tr key={idy}>
                                        {
                                            Object.values(row).map((value,idz)=>(
                                                <td key={idz}>
                                                    {value + ' '}
                                                </td>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>

                    </div>
                    :
                    null
            }
        </div>
    )
}

export default TestQueryResultsTable
