import React, {useEffect, useState} from "react";
import {getAsgList} from "../api/api";
import {setSpinner} from "../redux/actions/actions";
import {useDispatch} from "react-redux";
import ConnectionErr from "../components/Connection_Err";
import {Link} from "react-router-dom";


function ArchivedAsgListPg() {

    const dispatch=useDispatch()
    const [list, setList] = useState([]);
    const [message, setMessage] = useState('');

    useEffect(()=>{
        dispatch(setSpinner(true))
        getAsgList()
            .then(res=>{
                if (res.data.length) setList(res.data.filter(asg=>asg.archived))
                dispatch(setSpinner(false))
            })
            .catch(err=>{
                dispatch(setSpinner(false))
            })
    },[])

    return(
        <div>
            {/*Error*/}
            <ConnectionErr message={message} />
            {/*Title*/}
            <h4>Archived Assignments List</h4>
            <hr/>
            {/*Items*/}
            <div className="container-fluid">
                <div className="mt-2 py-3 row d-flex flex-wrap align-items-start">
                    {
                        list.length?
                            list.map((assignment,idx)=>(
                                <Link to={`/asgs/${assignment._id}`} style={{borderRadius:'.5rem',height:130,backgroundColor:`${assignment.type==='practice'?'#2ec8d5':'#2ed58f'}`}} className="Card-Lift mx-md-2 mx-0 d-flex flex-column mb-3 col-sm-6 col-md-4 col-lg-4 col-xl-3 align-items-center justify-content-center border shadow" key={idx}>
                                    <span style={{fontFamily:'Nunito-Bold',cursor:'default'}} className="text-white text-center">{assignment.name}</span>
                                    <br/>
                                    <span style={{fontFamily:'Nunito-Bold',cursor:'default'}} className="text-white text-center">{assignment.type==='practice'?'Practice':'Test'}</span>
                                </Link>
                            ))
                        :
                            <h6 className="text-danger">No archived assignments found</h6>
                    }
                </div>
            </div>
        </div>

    )
}

export default ArchivedAsgListPg;
