import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap/dist/js/bootstrap.min"
import rootReducer from "./redux/reducers/rootReducer";
// import {setToken} from "./api/api";
import {createStore} from "redux";
import {Provider} from "react-redux"
import {BrowserRouter as Router} from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import {setToken} from "./api/api";


// const User_id=localStorage.getItem('id');
// const User_token=localStorage.getItem('cosette_token');
const store=createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)
const token = localStorage.getItem('cosette_token');
if (token) {
    store.dispatch({ type: 'LOGIN' });
}
setToken();


ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <Router>
              <App />
          </Router>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
