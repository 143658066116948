import React, {useState,useEffect} from "react"
import {mustFilled, scrollToTop, stringTrimmer} from "../js/functions";
import {setSpinner} from "../redux/actions/actions";
import {createSqlTableReq} from "../api/api";
import faker from "faker";
import {useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
import {Parser} from "node-sql-parser";
import ConnectionErr from "../components/Connection_Err"


function CreateDB(props) {

    const parser = new Parser();
    const query='SELECT * FROM Tool'
    const ast = parser.astify(query);

    const addTableFields=[
        {
            name:"table_name"
        },
        {
            name:"create"
        },
        {
            name:"insert"
        },
    ]
    const createTableFields=[
        {
            name:"table_name"
        }
    ]
    const submitFields=[
        {
            name : 'name'
        }
    ]

    const dispatch=useDispatch()
    const [dbName, setDbName] = useState('');
    const [tableLine,setTableLine] = useState({
        // create:`CREATE TABLE Persons (  ${customFields.includes('firstName')?', FirstName varchar(255)':''} ${customFields.includes('lastName')?', LastName varchar(255)':''} ${customFields.includes('address')?', Address varchar(255)':''} ${customFields.includes('city')?', City varchar(255)':''} );`,
        // create:`CREATE TABLE Table ( PersonID int, LastName varchar(255), FirstName varchar(255), Address varchar(255), City varchar(255) );`,
        // insert:`INSERT INTO Table VALUES (1, 'David', 'Beckham', '901 Paradise Creek St', 'Moscow');`,
        create:``,
        insert:``,
        table_name:""
    })
    const [idCount,setIdCount]=useState(1)
    const [newTables, setNewTables] = useState([]);
    const [customFields,setCustomFields]=useState([  ])
    const [errors, setErrors] = useState({})
    const [message,setMessage]=useState('')
  

    const validation = (name,fields) => {
        let hasError
        let newErrors = errors
        if (name==='name'){
            hasError = mustFilled(dbName?dbName.trim():dbName)
        }
        else{
            hasError = mustFilled(tableLine[name]?tableLine[name].trim():tableLine[name])
        }
        if (hasError)
            newErrors[name] = hasError
        else {
            delete newErrors[name];
        }
        setErrors(Object.assign({}, newErrors))
    }
    const deleteExtraErrors=(fields)=>{
        const notWanted = Object.keys(errors).filter(item => !fields.includes(item))
        let newErrors = errors
        notWanted.map(i => delete newErrors[i])
        setErrors(Object.assign({}, newErrors))
    }


    // For adding names and column separately
    const handleAddTable=(e)=>{
        e.preventDefault();
        deleteExtraErrors(addTableFields)
        addTableFields.map(({name})=>validation(name))
        if (Object.entries(errors).length !== 0 && errors.constructor === Object) {
            return
        }
        else{
            if ( newTables.some(i=>i.name===tableLine.table_name) ){
                return alert(`You already have defined ${tableLine.table_name}`)
            }
            else{
                let tableCode,i,schema,tableName;
                tableCode=stringTrimmer(tableLine['create'])
                tableCode=tableCode.slice(12,tableCode.length)
                i=tableCode.indexOf('(')
                schema=stringTrimmer(tableCode.slice(i,tableCode.length).replace(/\s/g, ''))
                tableName=tableCode.slice(0,i)
                setNewTables([
                    ...newTables,
                    {
                        name:tableLine.table_name,
                        create:tableLine['create'],
                        insert:tableLine['insert']
                    }
                ])
                setTableLine({
                    create:``,
                    insert:``,
                    table_name:''
                })
                setIdCount(1)
            }
        }
   }

    const handleTableLineChange=(e)=>{
        const {name,value}=e.target;
        setTableLine({
            ...tableLine,
            [name]:value
        })
    }

    const handleChangeDBName=(e)=>{
        const {value}=e.target;
        e.preventDefault();
        setDbName(value)
    }

    const handleSubmit=(e)=>{
        e.preventDefault();
        deleteExtraErrors(submitFields)
        submitFields.map(({name})=>validation(name))
        if (Object.entries(errors).length !== 0 && errors.constructor === Object) {
            return
        }
        else{
            if (!newTables.length){
                alert('No Tables Are added!')
                return
            }
            else{
                dispatch(setSpinner(true))
                let body={
                    name:dbName,
                    tables:newTables
                }
                createSqlTableReq(body)
                    .then(res=>{
                        dispatch(setSpinner(false))
                        props.history.push('/dbs-list')
                    })
                    .catch(err=>{
                        if (err.response) {
                           setMessage(err.response.data.sqlMessage); 
                           scrollToTop()
                        } 
                        dispatch(setSpinner(false))
                    })
            }
        }
    }

    let count=0;
    const handleGenerateData=async (e,tableIndex)=>{
        e.preventDefault();
        deleteExtraErrors(createTableFields)
        createTableFields.map(({name})=>validation(name))
        if (Object.entries(errors).length !== 0 && errors.constructor === Object) {
            return
        }
        else{
            if (!tableLine.table_name.length){
                alert('Enter a name for your table')
                return
            }
            else{
                if ( newTables.some(i=>i.name===tableLine.table_name) ){
                    return alert(`You already have defined ${tableLine.table_name}`)
                }
                else{
                    let insert =  tableLine.insert.length?  `,(` + `${idCount}, ` + faker.fake("'{{name.firstName}}', '{{name.lastName}}', '{{address.streetName}}', '{{address.city}}'") + '); '  :   `INSERT INTO ${tableLine.table_name} VALUES(` + `${idCount}, ` + faker.fake("'{{name.firstName}}', '{{name.lastName}}', '{{address.streetName}}', '{{address.city}}'") + '); ' ;
                    let create = `CREATE TABLE ${tableLine.table_name} ( PersonID int, LastName varchar(255), FirstName varchar(255), Address varchar(255), City varchar(255) );`
                    if (count!==1){
                        if (!tableLine.create.length){
                            setTableLine({
                                ...tableLine,
                                create: create,
                                insert:(tableLine.insert.replace(';','')+' '+ insert)
                            })
                        }
                        else{
                            setTableLine({
                                ...tableLine,
                                insert:(tableLine.insert.replace(';','')+' '+ insert)
                            })
                        }
                    }
                    else{
                        
                    }
                    setIdCount(count=>count+1)
                }
            }
        }
    }

    // IF INSERT EMPTY, SET ID COUNT TO 1
    useEffect(()=>{
        if(!tableLine.insert.length){
            setIdCount(1)
        }
    },[tableLine.insert])



    return(
        <>

            {/*DB Name*/}
            <div className="mb-3">

                {/* Error */}
                <ConnectionErr message={message}/>

                {/*Table Title*/}
                <label>
                    Enter your DB name:
                </label>
                {/*Table Name*/}
                <input name={'name'} onChange={(e)=>handleChangeDBName(e)} className="form-control" value={dbName} placeholder={'Moscow Pets'} />
                {/*Input Error*/}
                {
                    errors['name']?
                        <div className="Input-Err-Div">
                            <span className="invalid-feedback d-block">
                                {errors['name']}
                            </span>
                        </div>
                        :
                        null
                }
            </div>

            {/*Table Name*/}
            <div className="mb-3">
                {/*Table Title*/}
                <label>
                    Enter your table name:
                </label>
                {/*Table Name*/}
                <input name={'table_name'} onChange={(e)=>handleTableLineChange(e)} className="form-control" value={tableLine['table_name']} placeholder={'Table1'} />
                {/*Input Error*/}
                {
                    errors['table_name']?
                        <div className="Input-Err-Div">
                            <span className="invalid-feedback d-block">
                                {errors['table_name']}
                            </span>
                        </div>
                        :
                        null
                }
            </div>

            {/*Tables*/}
            <div className="my-3">
                <div className="form-group">
                    {/*Table Title*/}
                    <label>
                        Enter the code for creating your table:
                    </label>
                    {/*Create Table*/}
                    <textarea name={'create'} onChange={(e)=>handleTableLineChange(e)} className="form-control" value={tableLine['create']} placeholder={'e.g. CREATE TABLE t1(a : Int, b : Int);'} />
                    {/*Input Error*/}
                    {
                        errors['create']?
                            <div className="Input-Err-Div">
                                <span className="invalid-feedback d-block">
                                    {errors['create']}
                                </span>
                            </div>
                        :
                            null
                    }
                </div>
                <div className="form-group">
                    {/*Table Title*/}
                    <label>
                        Enter the code for inserting values:
                    </label>
                    {/*Generate Data*/}
                    <textarea name={'insert'} onChange={(e)=>handleTableLineChange(e)} className="form-control" value={tableLine['insert']} placeholder={'e.g. INSERT DATA'} />
                    {/*Input Error*/}
                    {
                        errors['insert']?
                            <div className="Input-Err-Div">
                                <span className="invalid-feedback d-block">
                                    {errors['insert']}
                                </span>
                            </div>
                        :
                            null
                    }
                </div>
                {/*Add Button*/}
                <button style={{fontSize:10.5}} onClick={(e)=>handleAddTable(e)} className="mt-2 btn btn-sm btn-primary">Add Table</button>
                {/*Generate Data*/}
                <button style={{fontSize:10.5}} className="mt-2 btn btn-sm btn-secondary mx-2" onClick={(e)=>handleGenerateData(e)}>Fake Data</button>
            </div>

            {/*Created Tables*/}
            {
                newTables.length?
                    <div className="mt-5">
                        {/*Title*/}
                        <h5>
                            Tables to be submitted:
                        </h5>
                        {
                            newTables.map(({schema, name}, idx) => (
                                <React.Fragment key={idx}>
                                    <div className="fw-bold">
                                        {name} {schema}
                                    </div>
                                </React.Fragment>
                            ))
                        }
                    </div>
                :
                    null
            }

            {/*Submit Tables*/}
            <button onClick={(e)=>handleSubmit(e)} className="mt-4 mb-3 btn btn-sm btn-success">Submit Database</button>

        </>
    )
}
export default withRouter(CreateDB);
