import React, {useEffect} from 'react'
import {Logout, setSpinner} from "../redux/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import {Link, withRouter} from "react-router-dom"

function Dashboard(props){

    const dispatch=useDispatch()
    const type=useSelector(state=>state.utils.userType)
    const InstructorFields=[
        {
            name:'Manage Databases',
            url:"manage-dbs"
        },
        {
            name:'Create Assignment',
            url:"create-asg"
        },
        {
            name:'Assignments List',
            url:"asgs"
        },
        {
            name:'Archived Assignments',
            url:"asgs/archived"
        },
        {
            name:'Reports',
            url:"reports"
        },
        {
            name:'Submitted Assignments',
            url:"submitted-asgs"
        },
        {
            name:'Logout',
            url:"logout"
        },
    ];
    const StudentFields=[
        {
            name:'Assignments',
            url:"asgs"
        },
        {
            name:'Submitted Assignments',
            url:"submitted-asgs"
        },
        {
            name:'Logout',
            url:"logout"
        }
    ];

    // Spinner Off
    useEffect(() =>{
        dispatch(setSpinner(true))
        setTimeout(() => {
            dispatch(setSpinner(false))
        },2000)
    },[])

    const handleClick=(e,name)=>{
        if (name==='Logout'){
            e.preventDefault();
            dispatch(Logout())
        }
    }


    return(
        <div className="w-100 h-100">
            {/*Items Container*/}
            <div className="container-fluid">
                <div className="mt-2 py-3 row d-flex flex-wrap align-items-start">
                    {
                        (type==='115'?StudentFields:InstructorFields).map((item,idx)=>(
                            <Link to={item.url} onClick={(e)=>handleClick(e,item.name)} className="Card-Lift mx-md-2 mx-0 d-flex mb-3 col-sm-6 col-md-4 col-lg-4 col-xl-3 align-items-center justify-content-center border shadow" style={{borderRadius:'.5rem',height:130}} key={idx}>
                                <span style={{fontFamily:'Nunito-Bold'}} className="text-white text-center">{item.name}</span>
                            </Link>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default withRouter(Dashboard);
