import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import {useParams, withRouter} from "react-router-dom";
import {setSpinner} from "../redux/actions/actions";
import {
    archiveAsgItemReq,
    cosetteReq,
    deleteAsgReq,
    getAsgItemReq,
    getTablesOfDb, 
    submitAsgReq,
    unarchiveAsgItemReq, gradeAsgItemReq, getQuery
} from "../api/api";
import Table from "../components/Table";
import {cosette_api_token, mustFilled, stringTrimmer, toCosette} from "../js/functions";
import CorrectQueryResults from "../components/CorrectQueryResults";
import YourQueryResults from "../components/YourQueryResults";
import ConnectionErr from "../components/Connection_Err"
import SuccessMsg from "../components/SuccessMsg"






function ViewAsgnmnt(props){


    const {id}=useParams()
    const dispatch=useDispatch()
    const [Tables,SetTables]=useState([])
    const [message,setMessage]=useState('');
    const [success,setSuccess]=useState('')
    const [results,setResults]=useState(null)
    const [submitted,setSubmitted]=useState(null)
    const [std_queries,setStdQueries]=useState({})
    const [ins_queries,setInsQueries]=useState({})
    const [assignment,setAssignment]=useState(null)
    const [sentToServer,setSentToServer]=useState(false)
    const Practice=(assignment&&assignment.type==='practice')
    const type=useSelector(state=>state.utils.userType)
    const student=type==='115'
    const [tempRes,setTempRes]=useState({})
    const cosette=useSelector(state=>state.utils.cosette_code)
    const [points,setPoints]=useState(null)


    // Queries OnChange
    const handleChangeQueries=(e)=>{
        const {name,value}=e.target;
        setStdQueries({
            ...std_queries,
            [name]:value
        })
    }

    // Starts processing the queries and submits to Cosette
    const handleCosette=async (e)=>{

        // GET THROUGH ALL THE QUESTIONS AND FOR EACH ONE
        // CREATE A COSETTE BASED ON THEIR CANDIDATE
        // QUERY AND THE QUERY THAT STUDENT HAS
        // ENTERED; THEN SUBMIT THEM TO COSETTE, FINALLY
        // SAVES THE RESULT FOR EACH QUESTION IN THE "results" STATE
        
        let empty;
        Object.values(std_queries).map((i)=>{
            empty=mustFilled(i)
        })
        if(empty){
            alert('Enter queries for all of the questions')
            return
        }
        else{
            setResults(()=>null)
            setTempRes({})
            let promises = [];
            let TempResults = {}
            let resultsObj=results?results:{}
            let gradesObj=points?points:{}

            assignment.questions.map(({question,can_query,points})=>{
                dispatch(setSpinner(true))
                // SEND COSETTE PROGRAM TO API
                let cos_code;
                try{
                    cos_code=cosette
                        +" query q1 "
                        +"`"
                        +toCosette(can_query)
                        +"`; "
                        +" query q2 "
                        +"`"
                        + `${stringTrimmer(std_queries[question]).endsWith(';') ? toCosette(stringTrimmer(std_queries[question]).slice(0, -1)) : toCosette(stringTrimmer(std_queries[question]))}`
                        +"`; "
                        +" verify q1 q2;"
                    ;
                }
                catch(e){

                }
                
                let body=new FormData();
                body.append('api_key',cosette_api_token())
                body.append('query',cos_code)


                promises.push(
                    cosetteReq(body)
                        .then(res=>{
                            if(res.data.result==='ERROR'){
                                resultsObj[question]= res.data.coq_log
                            }
                            else resultsObj[question]=res.data.result

                            if (res.data.result==='NEQ'){
                                gradesObj[question] = 0
                                let queryPromises=[]
                                let queryResponsesObj=tempRes?tempRes:{}
                                
                                queryPromises.push(
                                    getQuery(assignment.db , std_queries[question])
                                        .then(res=>{
                                            queryResponsesObj={
                                                ...queryResponsesObj,
                                                [question] : {...queryResponsesObj[question],std:res.data}
                                            }
                                        })
                                        .catch(err=>{

                                        })
                                )
                                queryPromises.push(
                                    getQuery(assignment.db , ins_queries[question])
                                        .then(res=>{
                                            queryResponsesObj={
                                                ...queryResponsesObj,
                                                [question] : {...queryResponsesObj[question],ins:res.data}
                                            }
                                        })
                                        .catch(err=>{

                                        })
                                )

                                Promise.all(queryPromises)
                                    .then(() => {
                                        let objKeys =Object.keys(queryResponsesObj).map((i)=>i)
                                        let objVals =Object.values(queryResponsesObj).map((i)=>i)
                                        let temp={
                                            ...TempResults
                                        }
                                        objKeys.map((key,idx)=>(
                                            temp[key]=objVals[idx]
                                        ))
                                        let objj={}
                                        objj[question]=temp
                                        TempResults=temp
                                        setTempRes(temp)
                                    })
                            }

                            else {
                                if (res.data.result === 'EQ'){
                                    gradesObj [question] = points
                                }
                                else gradesObj [question] = 0
                                setTimeout(()=>{
                                    let objj={}
                                    objj=TempResults
                                    delete objj[question]
                                    setTempRes(objj)
                                },1500)
                            }
                        })
                        .catch(err=>{
                            dispatch(setSpinner(false))
                        })
                )
            })
            Promise.all(promises)
                .then(() => {
                    setResults(resultsObj)
                    setPoints(gradesObj)
                    dispatch(setSpinner(false))
                });
            } 
    }


    // Async/Await TO START THE COSETTE PROCESS
    const starterFunc=async ()=>{
        await handleCosette()
    }

    const handleSubmit=async(e)=>{
        e.preventDefault();
        if (Practice){
            await starterFunc()
        }
        else{
            await starterFunc()
            // WHEN THE RESULT IS RETURNED FROM
            // COSETTE, SUBMIT IT TO DATABASE
            // NOTE THAT EVEN IF IT RECEIVES
            // ERROR FROM THE COSETTE, IT STILL
            // SHOULD SUBMIT THE QUERIES TO DB
        }
    }

    const handleDeleteAsg=(e)=>{
        e.preventDefault();
        dispatch(setSpinner(true))
        deleteAsgReq(assignment._id)
            .then(res=>{
                props.history.push('/asgs')
                dispatch(setSpinner(false))
            })
            .catch(err=>{
                if (err.response) {
                    dispatch(setSpinner(false))
                }
            })
    }

    const handleArchiveAsg=(e)=>{
        e.preventDefault()
        dispatch(setSpinner(true))
        archiveAsgItemReq(assignment._id)
            .then(res=>{
                dispatch(setSpinner(false))
                props.history.goBack()
            })
            .catch(err=>{
                dispatch(setSpinner(false))
            })
    }

    const handleUnArchiveAsg=(e)=>{
        e.preventDefault()
        dispatch(setSpinner(true))
        unarchiveAsgItemReq(assignment._id)
            .then(res=>{
                dispatch(setSpinner(false))
                props.history.goBack()
            })
            .catch(err=>{
                dispatch(setSpinner(false))
            })
    }

    const handleGrading=(e)=>{
        e.preventDefault();
        dispatch(setSpinner(true))
        gradeAsgItemReq(assignment._id)
            .then(res=>{
                setSuccess('Successfully Graded!')
                setAssignment({
                    ...assignment,
                    graded:true
                })
                dispatch(setSpinner(false))
            })
            .catch(err=>{
                dispatch(setSpinner(false))
            })
    }

    // GET THE ASSIGNMENT
    useEffect(()=>{
        dispatch(setSpinner(true))
        getAsgItemReq(id)
            .then(res=>{
                setAssignment(res.data.list)
                setSubmitted(res.data.submitted)
                // SHOW THE RESULTS AND GRADES AND FEEDBACKS
                if(res.data.submitted){
                    setSentToServer(()=>true) 
                    if(res.data.list.grading==='immediate'){
                        setResults(res.data.submitted.results)
                        setTempRes(()=>res.data.submitted.difference)
                    }
                    else if(res.data.list.grading==='deferred' && res.data.list.graded){
                        setResults(res.data.submitted.results)
                        setTempRes(()=>res.data.submitted.difference)
                    }
                }
                dispatch(setSpinner(false))
            })
            .catch(err=>{
                if (err.response){
                    setSuccess('')
                    setMessage(err.response.data.message)
                }
                dispatch(setSpinner(false))
            })
    },[])

    // INITIAL SETUP FOR QUERIES STATE, WHEN IT IS THE INSTRUCTOR AND WANTS TO SEE THE ANSWERS
    useEffect(()=>{
        if (assignment){
            let obj1 = {}, obj2= {};

            if (student){
                assignment.questions.map(({question})=>{
                    obj1[question]=''
                })
                setStdQueries(obj1)
            }
            assignment.questions.map(({question,can_query,points})=>{
                obj2[question]=can_query
            })
            setInsQueries(obj2)
        }
    },[assignment])

    // GET ASSIGNMENT'S TABLES
    useEffect(()=>{
        if (assignment){
            dispatch(setSpinner(true))
            getTablesOfDb(assignment.db)
                .then(res=>{
                    SetTables(res.data.tables)
                    dispatch(setSpinner(false))
                })
                .catch(err=>{
                    dispatch(setSpinner(false))
                })
        }
    },[assignment])

    // IF ALL THE QUESTIONS ARE CHECKED WITH COSETTE, THEN SUBMIT THEM TO COSETTE SERVER
    let cnt=0
    useEffect(()=>{
        if (assignment && results && points && !sentToServer){
            cnt = cnt+1;
            let totalGrade=0;
            if ( cnt===1 &&  Object.entries(tempRes).length === Object.values(results).filter(val => val === 'NEQ').length ){
                if (Practice) dispatch(setSpinner(false))
                else{
                    Object.values(points).forEach((point)=>{
                        return totalGrade+=(+point)
                    })
                
                    let reqBody={
                        asg_id:id,
                        asg_name:assignment.name,
                        answers:std_queries,
                        results:results,
                        difference:tempRes,
                        totalGrade: totalGrade,
                        maxGrade:assignment.maxGrade
                    }

                    submitAsgReq(reqBody)
                        .then(res=>{
                            if (assignment.grading==='immediate'){
                                setSentToServer(() =>true)
                            }
                            else if (assignment.grading==='deferred'){
                                dispatch(setSpinner(true))
                                setTimeout(()=>{
                                    dispatch(setSpinner(false))
                                    alert('Assignment Submitted Successfully!')
                                    props.history.push('/asgs')
                                },1500)
                            }
                        })
                        .catch(err=>{
                        })
                        .finally(()=>{
                            dispatch(setSpinner(false))
                        })
                }
            }
        }
    },[tempRes,results,points])


    

    return(
        <div className="pb-3">


            {/* Error & Success */}
            <div className="mb-4">
                <ConnectionErr message={message} />
                <SuccessMsg message={success} / >
            </div>
            

            {/*Mode*/}
            {
                assignment?
                    <>
                        {/*Assignment Name*/}
                        <div className="form-group mb-4">
                            {/*Title*/}
                            <h3>
                                {assignment.name}
                            </h3>
                        </div>

                        {/*Assignment Type:*/}
                        <h5>Assignment Type:</h5>
                        <div className="form-group mb-3">
                            <p style={{fontFamily:'Nunito-Bold'}} className="text-danger">
                                {Practice?'Practice':'Test'}
                            </p>
                        </div>

                        {/*Assignment Type:*/}
                        {
                            !Practice?
                                <>
                                    <h5>Grading type:</h5>
                                    <div className="form-group mb-3">
                                        <p style={{fontFamily:'Nunito-Bold'}} className="text-danger">
                                            {assignment.grading}
                                        </p>
                                    </div>
                                </>
                            :
                                null
                        }

                        {/*Description*/}
                        <h5>Description</h5>
                        <div className="form-group mb-3">
                            <p>
                                {assignment.description}
                            </p>
                        </div>

                        {/*Tables*/}
                        <h5>Tables</h5>
                        <div className="mb-5">

                        {/*TABLES OF THE SELECTED DATABASE*/}
                        {
                            assignment&&
                            Tables.map((name,idx)=>(
                                <Table name={name} id={assignment.db} showTables={student? ( submitted ? true : assignment.showTables ) : true} {...props} key={idx} />
                            ))
                        }

                        </div>

                        {/* Grades */}
                        {
                            submitted?
                                (assignment.grading==='immediate' && sentToServer) || (assignment.grading==='deferred' && assignment.graded)&&                            
                                <h5 className="mb-4 p-2 text-success" style={{border:'1px solid lightgreen'}}>
                                    Your grade for this assignment is: {submitted.totalGrade}{ assignment.maxGrade && '/' + assignment.maxGrade}
                                </h5>
                            :
                                null  
                        }

                        {/* Not Graded */}
                        {
                            submitted && assignment.grading==='deferred' && !assignment.graded &&
                            <h5 className="text-secondary">
                                The assignment is not graded yet
                            </h5>
                        }

                        {/*Queries Title*/}
                        <h5 className="mb-3">
                            {
                                submitted?
                                    (assignment.grading==='immediate' && sentToServer) || (assignment.grading==='deferred' && assignment.graded)&&
                                    'Your entered queries:'
                                :
                                    'Write the query for the following questions:'
                            }
                        </h5>


                        {/*Questions*/}
                        <div className="">
                            {
                                assignment.questions.map(({question,can_query,points},idx)=>(
                                    <React.Fragment key={idx}>
                                        <div className="form-group">
                                            <span className="fw-bold">
                                                {question}
                                            </span>
                                            {
                                                !Practice?
                                                    <span className="fw-bold text-primary mb-0 ml-1">
                                                        <small>({points} points)</small>
                                                    </span>
                                                :
                                                null
                                            }
                                            <textarea 
                                                name={question} 
                                                value={ 
                                                    student? 
                                                        ( !submitted? std_queries[question] : submitted.answers.find(item=> Object.keys(item).some(i=>i===question))[question] )
                                                    :
                                                        (can_query)
                                                } 
                                                disabled={!student||(student&&submitted)} 
                                                className="form-control mt-1" 
                                                onChange={(e)=>handleChangeQueries(e)} 
                                                placeholder={'e.g. SELECT * FROM t1'}
                                            />
                                            {/*Input Error*/}
                                            {
                                                results?
                                                    (Practice) || (!Practice && assignment.grading==='immediate' && sentToServer) || (submitted && !Practice && assignment.grading==='deferred' && assignment.graded) ?
                                                        <div className="Input-Err-Div">
                                                            {/*Query Compare Feedback*/}
                                                            <span className={`${results[question]&&results[question]==='EQ'?'valid-feedback':'invalid-feedback'} d-block`}>
                                                                {
                                                                    results[question]&&
                                                                    results[question]==='EQ'?
                                                                        'Correct!':
                                                                            results[question]&&results[question]==='NEQ'?
                                                                                'Incorrect!'
                                                                            :
                                                                                results[question]
                                                                }
                                                            </span>
                                                            {/*Correct Query*/}
                                                            <span style={{fontSize:12}}>
                                                                {
                                                                    results[question] && results[question]!=='EQ'?
                                                                        <>
                                                                            Correct Query:
                                                                            <span className="text-success" style={{fontSize:12}}>
                                                                                 {' ' + can_query}
                                                                            </span>
                                                                        </>
                                                                    :
                                                                        ''
                                                                }
                                                            </span>
                                                        </div>
                                                    :
                                                            submitted && !Practice && assignment.grading==='deferred'&& 
                                                            <span>PROBLEM: {assignment.grading} {sentToServer?'true':'false'}</span>
                                                :
                                                    null
                                            }
                                        </div>

                                        {
                                            tempRes&&tempRes[question]?
                                                (Practice) || (!Practice && assignment.grading==='immediate' && sentToServer) || (!Practice && assignment.grading==='deferred' && submitted && assignment.graded) ?
                                                    <div className="mt-2">
                                                        {
                                                            tempRes&&
                                                            <CorrectQueryResults data={tempRes[question]} title='Correct Query Results:' />
                                                        }
                                                        {
                                                            tempRes&&
                                                            <YourQueryResults data={tempRes[question]} title='Your Query Results:' />
                                                        } 
                                                    </div>
                                                :
                                                    null
                                            :
                                                null
                                        }
                                    </React.Fragment>
                                ))
                            }
                        </div>

                        {/*Submit*/}
                        <div className="my-3">

                            {
                                student?
                                    !submitted && !sentToServer?
                                        <>
                                            {/*Submit Button*/}
                                            <button onClick={(e)=>handleSubmit(e)} className="btn btn-sm btn-success">{Practice?'Check Queries':'Submit Queries'}</button>
                                        </>
                                    :
                                        null
                                :
                                    <>
                                        {/*Delete Button*/}
                                        <button onClick={(e)=>handleDeleteAsg(e)} className="btn btn-sm btn-danger">Delete</button>
                                        {/*Archive Buttons*/}
                                        {
                                            !assignment.archived?
                                                <button className="btn btn-sm btn-info mx-2" onClick={(e)=>handleArchiveAsg(e)}>Archive</button>
                                            :
                                                <button className="btn btn-sm btn-info mx-2" onClick={(e)=>handleUnArchiveAsg(e)}>Unarchive</button>
                                        }
                                    </>
                            }

                            {/*Show Grading Options*/}
                            {
                                !student && !Practice && assignment.grading==='deferred'?
                                    !assignment.graded?
                                        <button onClick={(e)=>handleGrading(e)} className="btn btn-sm btn-success">Grade</button>
                                    :
                                        <span className="text-success">Assignment is graded!</span>
                                :
                                    null
                            }


                        </div>                        

                    </>
                :
                    null
            }
        </div>
    )
}


export default withRouter(ViewAsgnmnt);
