import React, {useEffect, useState} from "react"
import {useDispatch} from "react-redux";
import {Logout, setSpinner} from "../redux/actions/actions";
import {getDBsList} from "../api/api";
import {Link} from "react-router-dom";

function DbList(props) {

    const dispatch=useDispatch()
    const [existingTables, setExistingTables] = useState([]);

    // GET EXISTING TABLES ON STARTUP
    useEffect(()=>{
        dispatch(setSpinner(true))
        getDBsList()
            .then(res=>{
                if (res.data.databases.length) setExistingTables(res.data.databases)
                dispatch(setSpinner(false))
            })
            .catch(err=>{
                dispatch(setSpinner(false))
            })
    },[])

    const handleClick=(e,name)=>{
        if (name==='Logout'){
            e.preventDefault();
            dispatch(Logout())
        }
    }



    return(
        <>
            <h5>Databases:</h5>
            {/*Existing Tables*/}
            <div className="container-fluid">
                <div className="mt-2 py-3 row d-flex flex-wrap align-items-start">
                {
                    existingTables.length?
                        (existingTables).map(({Database},idx)=>(
                            true?
                                <Link to={`dbs-list/${Database}`} onClick={(e)=>handleClick(e,Database)} className="Card-Lift mx-md-2 mx-0 d-flex flex-column mb-3 col-sm-6 col-md-4 col-lg-4 col-xl-3 align-items-center justify-content-center border shadow" style={{borderRadius:'.5rem',height:130}} key={idx}>
                                    <span style={{fontFamily:'Nunito-Bold'}} className="text-white text-center">{Database}</span>
                                </Link>
                            :
                                null
                        ))
                    :
                        <h6 className="text-danger">No active DBs found</h6>
                }

                </div>
            </div>
        </>
    )
}
export default DbList;
