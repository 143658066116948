import React, {useEffect, useState} from "react"
import {setSpinner} from "../redux/actions/actions";
import {
    archiveDbReq,
    deleteDbReq, deleteTableReq,
    getTablesOfDb, getTableItem,
    unArchiveDbItem
} from "../api/api";
import {useDispatch} from "react-redux";
import {useParams, withRouter} from "react-router-dom";
import Table from "../components/Table";


function DbItem(props){

    const {id}=useParams();
    const dispatch=useDispatch()
    const [item,setItem]=useState()
    const [tables,setTables]=useState([])
    const [data,setData]=useState({})
    const [addEnabled,setAddEnabled]=useState(false)

    // GET THE DB ITEM
    useEffect(()=>{
        dispatch(setSpinner(true))
        getTablesOfDb(id)
            .then(res=>{
                setTables(res.data.tables)
                dispatch(setSpinner(false))
            })
            .catch(err=>{
                dispatch(setSpinner(false))
            })
    },[])

    const handleDeleteDb=(e)=>{
        e.preventDefault();
        dispatch(setSpinner(true))
        deleteDbReq(id)
            .then(res=>{
                props.history.push('/dbs-list')
                dispatch(setSpinner(false))
            })
            .catch(err=>{
                dispatch(setSpinner(false))
            })
    }


    return(
        <div className="py-3">
            {
                tables.length?
                    <div>
                        <h5 style={{fontFamily:"Nunito-Bold"}}>Database: {id}</h5>
                        {
                            tables.map((name,idx)=>(
                                <Table showTables={true} tablesLength={tables.length} history={props.history} name={name} edit={true} id={id} {...props} key={idx} />
                            ))
                        }
                        <button className="btn btn-sm btn-danger" onClick={(e)=>handleDeleteDb(e)}>Delete Database</button>
                    </div>
                :
                    <h6 className="text-danger">Database was not found</h6>
            }
            <br/>
            
            {/* <button className="btn btn-sm btn-danger" onClick={(e)=>handleDeleteDb(e)}>Delete Database</button> */}

        </div>
    )
}

export default withRouter(DbItem);
