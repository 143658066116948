
const INITIAL_STATE={
    spinner:false,
    questions:[],
    results:[],
    token:'',
    userType:null,
    schemas:[],
    cosette_code:""

}

const utilsReducer=(state=INITIAL_STATE, {type,payload})=>{
    switch (type) {
        case 'SET_SCHEMAS' :
            return Object.assign({},state,{
                schemas:[...state.schemas,payload]
            });
        case 'SET_COSETTE_CODE' :
            if (!state.cosette_code.includes(payload)){
                return Object.assign({},state,{
                    cosette_code:state.cosette_code+payload
                });
            }
        case 'QUESTIONS':
            return Object.assign({},state,{
                questions:payload
            });
        case 'SET_USER_TYPE':
            return Object.assign({},state,{
                userType:payload
            });
        case 'SPINNER':
            return Object.assign({},state,{
                spinner:payload
            });
        case 'SET_TOKEN':
            return Object.assign({},state,{
                token:payload
            });
        case 'SET_RESULTS':
            return Object.assign({},state,{
                results:payload
            });
        case 'SET_NEW_USER':
            return Object.assign({},state,{
                newUser:payload
            });
        default :
            return state
    }
}

export default utilsReducer;
