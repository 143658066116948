import React, {useEffect, useState} from "react"
import ConnectionErr from "../components/Connection_Err";
import {emailValidate, mustFilled, scrollToTop} from "../js/functions";
import {Login, setSpinner} from "../redux/actions/actions";
import {loginReq, registerReq, SetToken} from "../api/api";
import {useDispatch} from "react-redux";


function LoginPg(props){

    const dispatch=useDispatch()
    const LoginFields=[
        {
            name:"email",
            label:"Enter your email address:"
        },
        {
            name:"password",
            label:"Enter your password:"
        }
    ]
    const RegisterFields=[
        {
            name:"full_name",
            label:"Enter your full name:"
        },
        {
            name:"email",
            label:"Enter your email address:"
        },
        {
            name:"password",
            label:"Enter your password:"
        }
    ]
    const Modals=[
        {
            name:"RegisterModal",
            label:"Register"
        },
        {
            name:'LoginModal',
            label:"Login"
        }
    ]
    const [modal, setModal] = useState('LoginModal');
    const [loginValues,setLoginValues]=useState({
        email:"",
        password:''
    })
    const [errors, setErrors] = useState({})
    const [message, setMessage] = useState('')
    const [registerValues,setRegisterValues]=useState({
        email:"",
        password:'',
        full_name:'',
        type:'115'
        // 's' ASCII code: 115  (student),
        // 'i' ASCII code: 105  (instructor)
    })
    const handleLoginChange=(e)=>{
        const {name,value}=e.target;
        setLoginValues({
            ...loginValues,
            [name]:value
        })
    }
    const handleRegisterChange=(e)=>{
        const {name,value}=e.target;
        setRegisterValues({
            ...registerValues,
            [name]:value
        })
    }



    const validation = (name,values) => {
        let hasError
        let newErrors = errors
        if(name==='email'){
            hasError = emailValidate(values[name]?values[name].trim():values[name])
        }
        else{
            hasError = mustFilled(values[name]?values[name].trim():values[name])
        }
        if (hasError)
            newErrors[name] = hasError
        else {
            delete newErrors[name];
            setMessage('')
        }
        setErrors(Object.assign({}, newErrors))
    }

    const handleLogin=(e)=>{
        e.preventDefault();
        LoginFields.map(({name}) => validation(name,loginValues));
        if (Object.entries(errors).length !== 0 && errors.constructor === Object) {
            scrollToTop()
            setMessage('Wrong Inputs')
            return
        }
        else{
            dispatch(setSpinner(true))
            setMessage('')
            loginReq(loginValues)
                .then((res)=>{
                    SetToken(res.data.token);
                    const setStorage=async ()=>{
                        await localStorage.setItem('cosette_token', res.data.token)
                        await localStorage.setItem('u_type', res.data.type)
                    };
                    setStorage();
                    dispatch(Login())
                    window.location.reload()

                })
                .catch(err=>{
                    setMessage(err.response.data.message)
                    dispatch(setSpinner(false))
                })
        }
    }
    const handleRegister=(e)=>{
        e.preventDefault();
        RegisterFields.map(({name}) => validation(name,registerValues));
        if (Object.entries(errors).length !== 0 && errors.constructor === Object) {
            scrollToTop()
            setMessage('Wrong Inputs')
            return
        }
        else {
            dispatch(setSpinner(true))
            setMessage('')
            registerReq(registerValues)
                .then((res)=>{
                    SetToken(res.data.token);
                    const setStorage=async ()=>{
                        await localStorage.setItem('cosette_token', res.data.token)
                        await localStorage.setItem('u_type', res.data.type)
                    };
                    setStorage();
                    dispatch(Login())
                    window.location.reload()

                })
                .catch(err=>{
                    setMessage(err.response.data.message)
                    dispatch(setSpinner(false))
                })
        }
    }
    const handleSwitchForm=(e)=>{
        setMessage('')
        setModal(modal==='LoginModal'?'RegisterModal':'LoginModal')
        setErrors({})
    }


    return(
        <>
            <div style={{backgroundColor:"#ededed"}} className="w-100 h-100 d-flex py-3 bg-white align-items-center justify-content-center">
                <div style={{borderRadius:'.5rem',paddingTop:"1.5rem",paddingBottom:'1.5rem'}} className="Login-Card border shadow d-flex flex-column align-items-center justify-content-center">
                    {
                        Modals.map((mdl,idx)=>(
                            mdl.name===modal?
                                <div id={mdl.name} key={idx}>
                                    <div className="">
                                        {/*Modal Header*/}
                                        <div className="">
                                            <h4 className="">{mdl.label}</h4>
                                        </div>
                                        {/*Modal Body*/}
                                        <div className="">
                                            {/*Alert*/}
                                            <ConnectionErr message={message}/>
                                            {/*Modal Form*/}
                                            {
                                                ( mdl.name==='LoginModal' ? LoginFields : RegisterFields ).map(({name,label},idy)=>(
                                                    <div className="form-group d-flex flex-column mb-3" key={idy}>
                                                        {/*Input Label*/}
                                                        <label style={{color:"#545454"}}>
                                                            {label}
                                                        </label>
                                                        {/*Input*/}
                                                        <input
                                                            name={name}
                                                            type={name}
                                                            value={mdl.name==='LoginModal'?loginValues[name]:registerValues[name]}
                                                            className="form-control mt-1"
                                                            onChange={(e) =>
                                                                (mdl.name==='LoginModal'?handleLoginChange(e):handleRegisterChange(e))
                                                            }
                                                        />
                                                        {/*Input Error*/}
                                                        {
                                                            errors[(mdl.name==='LoginModal'?name:name)]?
                                                                <div className="Input-Err-Div">
                                                                    <span className="invalid-feedback d-block">
                                                                        {errors[(mdl.name==='LoginModal'?name:name)]}
                                                                    </span>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        {/*Modal Footer*/}
                                        <div className="modal-footer d-flex justify-content-center">
                                            <div className="d-flex align-items-center">
                                                <div className={"Login-Modal-Footer"}>
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-success"
                                                        onClick={(e)=>
                                                            (mdl.name==='LoginModal'?handleLogin(e):handleRegister(e))
                                                        }
                                                    >
                                                        {mdl.label}
                                                    </button>
                                                </div>
                                                {
                                                    mdl.name==='LoginModal'?
                                                        <div onClick={(e)=>handleSwitchForm(e)}>
                                                            <button className="btn btn-link btn-sm text-decoration-none">
                                                                Register
                                                            </button>
                                                        </div>
                                                    :
                                                        <div onClick={(e)=>handleSwitchForm(e)}>
                                                            <button className="btn btn-link btn-sm text-decoration-none">
                                                                Login
                                                            </button>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :
                                null
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default LoginPg;
