import React, {useEffect} from "react";
import {Route, Switch, withRouter} from "react-router-dom";
import IsAuthenticated from "./middleware/isAuthenticatd";
import Introduction from "./pages/Introduction";
import ViewAsgnmnt from "./pages/ViewAsgnmnt";
import Header from "./components/Header"
import LoginPg from "./pages/LoginPg";
import {useDispatch, useSelector} from "react-redux";
import PrivateRoute from "./middleware/PrivateRoute";
import OnlyInstructor from "./middleware/onlyInstructor";
import Dashboard from "./pages/Dashboard";
import {setUserType} from "./redux/actions/actions";
import AsgListPg from "./pages/AsgListPg";
import CreateAsgPg from "./pages/CreateAsgPg";
import UsersList from "./pages/UsersList";
import SubmittedList from "./pages/SubmittedList";
import ManageDBs from "./pages/ManageDBs";
import CreateDB from "./pages/createDb";
import DbList from "./pages/DbList";
import DbItem from "./pages/DbItem";
import ArchivedAsgListPg from "./pages/ArchivedAsgList";
import ArchivedDbsList from "./pages/ArchivedDbsList";
import SubmittedItem from "./pages/SubmittedItem";
import SubmittedItemsForThisAsg from "./pages/SubmittedItemsForThisAsg"
import ReportList from "./pages/ReportList";
import ReportForThisAsg from "./pages/ReportForThisAsg";
import { getIndexReq } from "./api/api";




function App(props) {

    // 's' ASCII code: 115  (student),
    // 'i' ASCII code: 105  (instructor)
    const dispatch=useDispatch()
    const Spinner=useSelector(state=>state.utils.spinner)


    // LOOKING TO SEE IF USER TYPE IS INSTRUCTOR
    useEffect(() => {
        dispatch(setUserType(localStorage.getItem('u_type')))
    }, []);

    return (
        <>
            {/*Spinner*/}
            {
                Spinner?
                    <div className="Spinner-Container">
                        <div className="spinner-grow text-info" />
                        <div className="spinner-grow text-warning" />
                        <div className="spinner-grow text-danger" />
                    </div>
                    :
                    null
            }

            {/*Content*/}
            <div style={{width:"100%",height:"100%",position:'relative'}}>

                {/*Header*/}
                <Header />

                {/*Main*/}
                <main style={{paddingBottom:"4%",paddingTop:"1.5%"}} className="App py-3">
                    <div className="container">
                        <Switch>
                            <Route exact path="/" component={Introduction} />
                            <IsAuthenticated exact path="/login" component={LoginPg} />
                            <PrivateRoute exact path="/dashboard" component={Dashboard} />
                            <PrivateRoute exact path="/asgs" component={AsgListPg} />
                            <PrivateRoute exact path="/asgs/archived" component={ArchivedAsgListPg} />
                            <PrivateRoute exact path="/asgs/:id" component={ViewAsgnmnt} />
                            <PrivateRoute exact path="/asgs/:id/:submitId" component={SubmittedItem} />
                            <PrivateRoute exact path="/submitted-asgs" component={SubmittedList} />
                            <OnlyInstructor exact path="/submitted-asgs/:id" component={SubmittedItemsForThisAsg} />
                            <OnlyInstructor exact path="/reports" component={ReportList} />
                            <OnlyInstructor exact path="/reports/:id" component={ReportForThisAsg} />
                            <OnlyInstructor exact path="/create-asg" component={CreateAsgPg} />
                            <OnlyInstructor exact path="/manage-dbs" component={ManageDBs} />
                            <OnlyInstructor exact path="/create-db" component={CreateDB} />
                            <OnlyInstructor exact path="/dbs-list" component={DbList} />
                            <OnlyInstructor exact path="/dbs-list/archived" component={ArchivedDbsList} />
                            <OnlyInstructor exact path="/dbs-list/:id" component={DbItem} />
                            <OnlyInstructor exact path="/users" component={UsersList} />
                        </Switch>
                    </div>
                </main>
            </div>
        </>

  );
}

export default withRouter(App);
