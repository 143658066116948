import React from "react"

function CreatedQuestionsList({questions}){

    return(
        <>
            {
                questions.length?
                    <div className="mb-2">
                        {/*Title*/}
                        <h5>
                            Your Questions:
                        </h5>
                        {
                            questions.map(({question,can_query,points}, idx) => (
                                <div className="fw-bold" key={idx}>
                                    {question}
                                    <br/>
                                    <code>{can_query}</code>
                                    {
                                        points&&
                                        <>
                                         <br />
                                         {points}
                                        </>
                                    }
                                </div>
                            ))
                        }
                    </div>
                :
                    null
            }
        </>
    )
}

export default CreatedQuestionsList;
