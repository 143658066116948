import React, {useEffect, useState} from "react"
import {setCosetteCode, setSchemas, setSpinner} from "../redux/actions/actions";
import ConnectionErr from "../components/Connection_Err"
import {addTableRowReq, deleteDbReq, deleteTableReq, getTableItem} from "../api/api";
import {useDispatch} from "react-redux";
import {withRouter} from "react-router-dom"
import {mustFilled, stringTrimmer} from "../js/functions";
import faker from "faker";



function Table({name,id,edit,history,tablesLength,update,showTables},props){


    const addTableFields=[
        {
            name:"create"
        },
        {
            name:"insert"
        },
    ]
    const addTableFieldsEdit=[
        {
            name:"insert"
        },
    ]
    const dispatch=useDispatch()
    const [rows,setRows]=useState([])
    const [idCount,setIdCount]=useState(1)
    const [message,setMessage]=useState('')
    const [newTables, setNewTables] = useState([]);
    const [tablesShown,settablesShown]=useState(false)
    const [addEnabled,setAddEnabled]=useState(false)
    const [thisTableSchema,setThisTableSchema]=useState(null)
    const [tableLine,setTableLine] = useState({
        create:``,
        insert:``,
        table_name:""
    })
    const [errors, setErrors] = useState({})
   
   
    const handleDeleteDb=(e)=>{
        e.preventDefault();
        dispatch(setSpinner(true))
        deleteDbReq(id)
            .then(res=>{
                history.push('/dbs-list')
                dispatch(setSpinner(false))
            })
            .catch(err=>{
                dispatch(setSpinner(false))
            })
    }

    const handleDeleteTable=(e)=>{
        e.preventDefault();
        dispatch(setSpinner(true))
        deleteTableReq(id,name)
            .then(res=>{
                dispatch(setSpinner(false))
                if(tablesLength===1){
                    handleDeleteDb(e)
                }
                else {
                    window.location.reload()
                    history.push(`/dbs-list/${id}`)
                }
            })
            .catch(err=>{
                dispatch(setSpinner(false))
                history.push(`/dbs-list/${id}`)
            })
    }

    const handleSeeData=(e)=>{
        e.preventDefault();
        settablesShown(!tablesShown)
    }

    // UNLOAD, GET TABLES
    useEffect(()=>{
        setRows([])
        settablesShown(false)
        dispatch(setSpinner(true))
        getTableItem(id,name)
            .then(res=>{
                setRows(res.data.tables)
                setThisTableSchema(res.data.schema)
                dispatch(setSchemas(res.data.schema))
                dispatch(setSpinner(false))
            })
            .catch(err=>{
                dispatch(setSpinner(false))
            })
    },[name,id])



    const validation = (name,fields) => {
        let hasError
        let newErrors = errors
        hasError = mustFilled(tableLine[name]?tableLine[name].trim():tableLine[name])
        
        if (hasError)
            newErrors[name] = hasError
        else {
            delete newErrors[name];
        }
        setErrors(Object.assign({}, newErrors))
    }
    const deleteExtraErrors=(fields)=>{
        const notWanted = Object.keys(errors).filter(item => !fields.includes(item))
        let newErrors = errors
        notWanted.map(i => delete newErrors[i])
        setErrors(Object.assign({}, newErrors))
    }

    // WHEN SCHEMA WAS SET, SEND SCHEMAS TO REDUX
    useEffect(()=>{
        if (thisTableSchema){
            const schem=
                `schema sch_${name}(`
                + thisTableSchema.map(({Type, Field},idx) =>
                    (Field + ' : ' + `${Type.includes('(')? Type.slice(0,Type.indexOf("(")) : Type}`  ))
                +'); '
            dispatch(setCosetteCode(schem))
        }
    },[thisTableSchema])

    // ONLOAD, SEND TABLES TO REDUX
    useEffect(()=>{
        dispatch(setCosetteCode(`table ${name}(sch_${name}); `))
    },[])

    // For adding names and column separately
    const handleAddTable=(e)=>{
        e.preventDefault();
        setMessage('')
        if(edit){
            deleteExtraErrors(addTableFieldsEdit)
            addTableFieldsEdit.map(({name})=>validation(name))
        }
        else{
            deleteExtraErrors(addTableFields)
            addTableFields.map(({name})=>validation(name))
        }
        if (Object.entries(errors).length !== 0 && errors.constructor === Object) {
            return
        }
        else{
            dispatch(setSpinner(true))
            if(!edit){
                let tableCode,i,schema,tableName;
                tableCode=stringTrimmer(tableLine['create'])
                tableCode=tableCode.slice(12,tableCode.length)
                i=tableCode.indexOf('(')
                schema=stringTrimmer(tableCode.slice(i,tableCode.length).replace(/\s/g, ''))
                tableName=tableCode.slice(0,i)
            }
            
            let body={
                name:id,
                insert:tableLine['insert']
            }
            
            addTableRowReq(body)
                .then(res=>{
                    setNewTables([
                        ...newTables,
                        {
                            name:name,
                            create:tableLine['create'],
                            insert:tableLine['insert']
                        }
                    ])
                    setTableLine({
                        create:``,
                        insert:``,
                        table_name:''
                    })
                    setIdCount(1)
                    dispatch(setSpinner(false))
                    window.location.reload()
                })
                .catch(err=>{
                    if (err.response) {
                        setMessage(err.response.data.sqlMessage)
                    } 
                    else {
                        setMessage('Connection Error!')
                    } 
                    dispatch(setSpinner(false))
                })
        }
   }

    const handleTableLineChange=(e)=>{
        const {name,value}=e.target;
        setTableLine({
            ...tableLine,
            [name]:value
        })
    }


    let count=0;
    const handleGenerateData=async (e,tableIndex)=>{
        e.preventDefault();
        let insert =  tableLine.insert.length?  `,(` + `${idCount}, ` + faker.fake("'{{name.firstName}}', '{{name.lastName}}', '{{address.streetName}}', '{{address.city}}'") + '); '  :   `INSERT INTO ${name} VALUES(` + `${idCount}, ` + faker.fake("'{{name.firstName}}', '{{name.lastName}}', '{{address.streetName}}', '{{address.city}}'") + '); ' ;
        let create=`CREATE TABLE ${name} ( PersonID int, LastName varchar(255), FirstName varchar(255), Address varchar(255), City varchar(255) );`

        if (count!==1){
            if (!tableLine.create.length){
                setTableLine({
                    ...tableLine,
                    create: create,
                    insert:(tableLine.insert.replace(';','')+' '+insert)
                })
            }
            else{
                setTableLine({
                    ...tableLine,
                    insert:(tableLine.insert.replace(';','')+' '+insert)
                })
            }
        }
        else{
           
        }
        setIdCount(count=>count+1)
    }


    // IF INSERT EMPTY, SET ID COUNT TO 1
    useEffect(()=>{
        if(!tableLine.insert.length){
            setIdCount(1)
        }
    },[tableLine.insert])





    return(
        <div className="mt-3">

            {/* Connection Error */}
            <ConnectionErr message={message} />

            <span style={{fontFamily:"Nunito-Bold"}}>
                {name}
            </span>

            <br/>

            {/*SCHEMA*/}
            <span style={{fontFamily:"Nunito-Bold"}}>
                {
                    thisTableSchema?
                        <>
                            <code>schema sch_{name}(
                                {
                                    thisTableSchema.map(({Type, Field},idx) => (<code key={idx}>{Field} {Type}{idx!==(thisTableSchema.length-1)?',':null} </code>))
                                }
                            );
                            </code>
                            <br/>
                        </>

                    :
                        null
                }
                {
                    <>
                        <code>table {name}(sch_{name});</code>
                        <br/>
                    </>
                }
            </span>

            {
                ! tablesShown ?
                    showTables?
                        <>
                            {/*SHOW DATA BUTTON*/}
                            <button style={{fontSize:10.5}} className="btn btn-sm btn-info my-1 mr-1" onClick={(e)=>handleSeeData(e)}>Show Data</button>
                        </>
                    :
                        null
                :
                    rows.length?
                        // DRAW TABLE
                        <div className="mt-2">
                            {/*SCHEMA*/}
                            {/*{thisTableSchema[0].map(({Type,Field})=>(Type))}*/}
                            {/*TABLE*/}
                            <table className="table table-bordered">
                                {/*KEYS*/}
                                <thead>
                                <tr>
                                    {Object.keys(rows[0]).map((key,idx)=>(
                                        <th key={idx}>
                                            {key + ' '}
                                        </th>
                                    ))}
                                </tr>
                                </thead>
                                {/*ROWS*/}
                                <tbody>
                                {
                                    (rows).map((row,idy)=>(
                                        <tr key={idy}>
                                            {
                                                Object.values(row).map((value,idz)=>(
                                                    <td key={idz}>
                                                        {value + ' '}
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                    :
                        null
            }

            {/* DELETE TABLE BTN */}
            {
                edit&&
                <>
                    <button style={{fontSize:10.5}} className={`${addEnabled ? 'btn-secondary' : 'btn-info'} btn btn-sm mr-1 my-1`} onClick={(e)=>setAddEnabled(!addEnabled)}>{addEnabled?'Cancel':'Add Row'}</button>
                    <button style={{fontSize:10.5}} className="btn btn-sm btn-danger my-1" onClick={(e)=>handleDeleteTable(e)}>Delete Table</button>
                </>
            }

            {/* Add Row Inputs */}
            {
                addEnabled?
                    <div className="mt-2">
                        {
                            !edit?
                                <>
                                    {/*Table Title*/}
                                    <label>
                                        Enter the code for creating your table:
                                    </label>
                                    {/*Create Table*/}
                                    <textarea name={'create'} onChange={(e)=>handleTableLineChange(e)} className="form-control" value={tableLine['create']} placeholder={'e.g. CREATE TABLE t1(a : Int, b : Int);'} />
                                
                                    <div className="form-group">
                        
                                        {/*Input Error*/}
                                        {
                                            errors['create']?
                                                <div className="Input-Err-Div">
                                                    <span className="invalid-feedback d-block">
                                                        {errors['create']}
                                                    </span>
                                                </div>
                                            :
                                                null
                                        }
                                    </div>
                                </>
                            :
                                ''
                        }


                        <div className="form-group">
                            {/*Table Title*/}
                            <label>
                                Enter the code for inserting values:
                            </label>
                            {/*Generate Data*/}
                            <textarea name={'insert'} onChange={(e)=>handleTableLineChange(e)} className="form-control" value={tableLine['insert']} placeholder={'e.g. INSERT DATA'} />
                            {/*Input Error*/}
                            {
                                errors['insert']?
                                    <div className="Input-Err-Div">
                                        <span className="invalid-feedback d-block">
                                            {errors['insert']}
                                        </span>
                                    </div>
                                :
                                    null
                            }
                        </div>
                        {/*Add Button*/}
                        <button style={{fontSize:10.5}} onClick={(e)=>handleAddTable(e)} className="mt-2 btn btn-sm btn-success">{edit?'Submit Row': 'Submit Table'}</button>
                        {/*Generate Data*/}
                        <button style={{fontSize:10.5}} className="mt-2 btn btn-sm btn-secondary mx-2" onClick={(e)=>handleGenerateData(e)}>Fake Data</button>  
                    </div>
                :
                    null
            }
            
            <hr/>
        </div>
    )
}
export default withRouter(Table);
