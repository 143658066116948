import React, {useEffect, useState} from "react";
import {getUsersReq} from "../api/api";
import {setSpinner} from "../redux/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import ConnectionErr from "../components/Connection_Err";


function UsersList() {

    const dispatch=useDispatch()
    const type=useSelector(state=>state.utils.userType)

    const [users, setUsers] = useState([]);
    const [message, setMessage] = useState('');

    useEffect(()=>{
        dispatch(setSpinner(true))
        if (type==='105'){
            getUsersReq()
                .then(res=>{
                    setUsers(res.data)
                    setTimeout(()=>{
                        dispatch(setSpinner(false))
                    },1000)
                })
                .catch(err=>{
                    setMessage('Connection Error!')
                    dispatch(setSpinner(false))
                })
        }
        else{

        }

    },[])

    return(
        <div>
            {/*Error*/}
            <ConnectionErr message={message} />
            {/*Title*/}
            <h4>Users List</h4>
            <hr/>
            {/*Users*/}
            <div className="container-fluid">
                <div className="mt-2 py-3 row d-flex flex-wrap align-items-start">
                    {
                        users.length?
                            users.map((user,idx)=>(
                                <div
                                    key={idx}
                                    style={{borderRadius:'.5rem',height:130,backgroundColor:`${user.type==='115'?'#2ec8d5':'#2ed58f'}`}}
                                    className="Card-Lift mx-md-2 mx-0 d-flex flex-column mb-3 col-sm-6 col-md-4 col-lg-4 col-xl-3 align-items-center justify-content-center border shadow"
                                >
                                    <span style={{fontFamily:'Nunito-Bold',cursor:'default'}} className="text-white text-center">{user.name}</span>
                                    <br/>
                                    <span style={{fontFamily:'Nunito-Bold',cursor:'default'}} className="text-white text-center">{user.email}</span>
                                    <br/>
                                    <small style={{fontFamily:'Nunito-Bold',cursor:'default'}} className="text-warning text-center">{user.type==='105'?'Instructor':'Student'}</small>
                                </div>
                            ))
                        :
                            null
                    }
                </div>
            </div>
        </div>

    )
}

export default UsersList;
