import Axios from "axios";

const axiosAgent = Axios.create({
    // baseURL: 'http://localhost:8082',
    baseURL: 'https://visqlnode.smartdblab.org',
});
// Examples
export const reportReq = () => axiosAgent.get('/api/v1/history');
export const cancelReq = (body) => axiosAgent.post('/api/v1/cancel', body);

// Token Set
const Token = localStorage.getItem('cosette_token')
export const setToken = () => axiosAgent.defaults.headers.common['Authorization'] = Token
export const SetToken = (stateTok) => axiosAgent.defaults.headers.common['Authorization'] = stateTok

// Actual Apis
export const getIndexReq = () => axiosAgent.get('/');
export const getUsersReq = () => axiosAgent.get('/users');
export const loginReq = (body) => axiosAgent.post('/auth/login', body);
export const registerReq = (body) => axiosAgent.post('/auth/register', body);
export const createAsgReq = (body) => axiosAgent.post('/create-asg', body);
export const createSqlTableReq = (body) => axiosAgent.post('/create-db', body);
export const addTableRowReq = (body) => axiosAgent.post('/add-table-row', body);
export const submitAsgReq = (body) => axiosAgent.post('/submit-asg', body);
export const getReportsList = () => axiosAgent.get('/reports-list');
export const getReportsForAsg = (id) => axiosAgent.get('/reports-for-asg',{params:{id:id}});
export const getAsgList = () => axiosAgent.get('/list-asg');
export const getDBsList = () => axiosAgent.get('/list-db');
export const getTablesOfDb = (id) => axiosAgent.get('/db-item',{params:{id:id}});
export const getTableItem = (db,table) => axiosAgent.get('/table-item',{params:{db:db,table:table}});
export const getQuery = (db,query) => axiosAgent.get('/query',{params:{db:db,query:query}});
export const archiveDbReq = (id) => axiosAgent.put('/db-item/archive',{params:{id:id}});
export const unArchiveDbItem = (id) => axiosAgent.put('/db-item/unarchive',{params:{id:id}});
export const deleteDbReq = (id) => axiosAgent.get('/delete-db',{params:{id:id}});
export const deleteTableReq = (db,table) => axiosAgent.get('/delete-table',{params:{db:db,table:table}});
export const getSubmissionsOfTheStudent = () => axiosAgent.get('/submissions-of-the-student');
export const getSubmissionList = () => axiosAgent.get('/submissions-list');
export const getSubmissionsForThisAsg = (id) => axiosAgent.get('/submissions-for-this-asg',{params:{id:id}});
export const getSubmittedItem = (id) => axiosAgent.get('/submitted-item-asg',{params:{id:id}});

export const getAsgItemReq = (id) => axiosAgent.get('/view-asg',{params:{id:id}});
export const archiveAsgItemReq = (id) => axiosAgent.put('/view-asg/archive',{params:{id:id}});
export const unarchiveAsgItemReq = (id) => axiosAgent.put('/view-asg/unarchive',{params:{id:id}});
export const gradeAsgItemReq = (id) => axiosAgent.put('/view-asg/grade',{params:{id:id}});
export const deleteAsgReq = (id) => axiosAgent.get('/delete-asg',{params:{id:id}});
export const cosetteReq = (body) => Axios.post('https://demo.cosette.cs.washington.edu/solve', body);



