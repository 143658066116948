import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {getReportsList} from "../api/api";
import {setSpinner} from "../redux/actions/actions";
import ConnectionErr from "../components/Connection_Err";


function ReportList() {

    const dispatch=useDispatch()
    const [list, setList] = useState([]);
    const [message, setMessage] = useState('');
    const type=useSelector(state=>state.utils.userType)

    // GET ASSIGNMENTS LIST
    useEffect(()=>{
        dispatch(setSpinner(true))
        getReportsList()
            .then(res=>{
                setList(res.data)
                dispatch(setSpinner(false))
            })
            .catch(err=>{
                dispatch(setSpinner(false))
            })
    },[])



    return(
        <div>
            {/*Error*/}
            <ConnectionErr message={message} />
            {/*Title*/}
            <h4>Reports List</h4>
            <hr/>
            {/*Items*/}
            <div className="container-fluid">
                <div className="mt-2 py-3 row d-flex flex-wrap align-items-start">
                    {
                        list.length?
                            list.map((assignment,idx)=>(
                                !assignment.archived?
                                    <Link 
                                            to={`/reports/${assignment._id}`} 
                                            style={{borderRadius:'.5rem',height:130,backgroundColor:`${assignment.type==='practice'?'#2ec8d5':'#2ed58f'}`}} 
                                            className="Card-Lift mx-md-2 mx-0 d-flex flex-column mb-3 col-sm-6 col-md-4 col-lg-4 col-xl-3 align-items-center justify-content-center border shadow" 
                                            key={idx}
                                        >
                                        <span style={{fontFamily:'Nunito-Bold',cursor:'default',fontSize:15}} className="text-white text-center">{assignment.name}</span>
                                        <br/>
                                        <span style={{fontFamily:'Nunito-Bold',cursor:'default',fontSize:13}} className="text-white text-center">{assignment.grading}</span>
                                    </Link>
                                :
                                    null
                            ))
                    :
                            <h6 className="text-danger">No reports found</h6>
                    }
                </div>
            </div>
        </div>
    )
}

export default ReportList;
