import React from "react"

function QueryFeedback({data,eq}) {


    return(
        <>
            {
                data.length?
                    <div className="Input-Err-Div">
                        <span className={`${data && data==='EQ'?'valid-feedback':'invalid-feedback'} d-block`}>
                            {data && data==='EQ'? (eq?'Equivalent!':'Correct!') :data && data==='NEQ'? (eq?'Not Equivalent':'Incorrect!') : data}
                        </span>
                    </div>
                :
                    null
            }
        </>
    )
}
export default QueryFeedback;
