import React,{useEffect,useState} from "react"
import { useDispatch,useSelector } from "react-redux"
import {useParams,Link} from "react-router-dom"
import {getSubmissionsForThisAsg} from "../api/api";
import {setSpinner} from "../redux/actions/actions"
import ConnectionErr from "../components/Connection_Err";


function SubmittedItemsForThisAsg(props){

    const {id}=useParams()
    const dispatch=useDispatch()
    const [message, setMessage] = useState('');
    const [list, setList] = useState([]);
    const type=useSelector(state=>state.utils.userType)

    // GET SUBMITTED LIST
    useEffect(()=>{
        dispatch(setSpinner(true))
        getSubmissionsForThisAsg(id)
            .then(res=>{
                setList(res.data)
                dispatch(setSpinner(false))
            })
            .catch(err=>{
                dispatch(setSpinner(false))
            })
    },[])

    return(
         <div>
            {/*Error*/}
            <ConnectionErr message={message} />
            {/*Title*/}
            <h4>{list.length?list[0].asg_name:'Submitted Items'}</h4>
            <hr/>
            {/*Items*/}
            <div className="container-fluid">
                <div className="mt-2 py-3 row d-flex flex-wrap align-items-start">
                    {
                        list.length?
                            list.map((sub_item,idx)=>(
                                <Link to={`/asgs/${id}/${sub_item._id}`} style={{borderRadius:'.5rem',height:130}} className="Card-Lift mx-md-2 mx-0 d-flex flex-column mb-3 col-sm-6 col-md-4 col-lg-4 col-xl-3 align-items-center justify-content-center border shadow" key={idx}>
                                    <span style={{fontFamily:'Nunito-Bold',cursor:'default',fontSize:13.5}} className="text-white text-center">Student: {sub_item.std_name}</span>
                                    <br/>
                                    <span style={{fontFamily:'Nunito-Bold',cursor:'default',fontSize:13.5}} className="text-white text-center">Student_ID: {sub_item.std_id}</span>
                                </Link>
                            ))
                        :
                            <h6 className="text-danger">No submitted assignments found</h6>
                    }
                </div>
            </div>
        </div>
    )
}

export default SubmittedItemsForThisAsg;