import React, {useState} from "react"
import {emailValidate, mustFilled, scrollToTop} from "../js/functions";
import {loginReq, registerReq, SetToken} from "../api/api";
import ConnectionErr from "./Connection_Err";
import {Login, setSpinner} from "../redux/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import {withRouter} from "react-router-dom"
import $ from "jquery"


function Header(props){


    const dispatch=useDispatch()
    const logged=useSelector(state=>state.log)
    const LoginFields=[
        {
            name:"email",
            label:"Enter your email address:"
        },
        {
            name:"password",
            label:"Enter your password:"
        }
    ]
    const RegisterFields=[
        {
            name:"full_name",
            label:"Enter your full name:"
        },
        {
            name:"email",
            label:"Enter your email address:"
        },
        {
            name:"password",
            label:"Enter your password:"
        }
    ]
    const Modals=[
        {
            name:"RegisterModal",
            label:"Register"
        },
        {
            name:'LoginModal',
            label:"Login"
        }
    ]
    const Navbar=[
        {
            name:'Home',
            href:'/'
        },
        {
            name:'Login',
            href:'/'
        },
    ]
    const [loginValues,setLoginValues]=useState({
        email:"",
        password:''
    })
    const [registerValues,setRegisterValues]=useState({
        email:"",
        password:'',
        full_name:'',
        type:'115'
        // 's' ASCII code: 115  (student),
        // 'i' ASCII code: 105  (instructor)
    })
    const handleLoginChange=(e)=>{
        const {name,value}=e.target;
        setLoginValues({
            ...loginValues,
            [name]:value
        })
    }
    const handleRegisterChange=(e)=>{
        const {name,value}=e.target;
        setRegisterValues({
            ...registerValues,
            [name]:value
        })
    }

    const [errors, setErrors] = useState({})
    const [message, setMessage] = useState('')

    const validation = (name,values) => {
        let hasError
        let newErrors = errors
        if(name==='email'){
            hasError = emailValidate(values[name]?values[name].trim():values[name])
        }
        else{
            hasError = mustFilled(values[name]?values[name].trim():values[name])
        }
        if (hasError)
            newErrors[name] = hasError
        else {
            delete newErrors[name];
            setMessage('')
        }
        setErrors(Object.assign({}, newErrors))
    }

    const handleLogin=(e)=>{
        e.preventDefault();
        setErrors({})
        LoginFields.map(({name}) => validation(name,loginValues));
        if (Object.entries(errors).length !== 0 && errors.constructor === Object) {
            scrollToTop()
            setMessage('Wrong Inputs')
            return
        }
        else{
            dispatch(setSpinner(true))
            setMessage('')
            loginReq(loginValues)
                .then((res)=>{
                    SetToken(res.data.token);
                    const setStorage=async ()=>{
                        await localStorage.setItem('cosette_token', res.data.token)
                        await localStorage.setItem('u_type', res.data.type)
                    };
                    setStorage();
                    $('#LoginModal').modal('hide')
                    dispatch(Login())
                    props.history.push('/dashboard')
                    dispatch(setSpinner(false))
                })
                .catch(err=>{
                    if (err&&err.response&&err.response.data&&err.response.data.message){
                        setMessage(err.response.data.message)
                    }
                    dispatch(setSpinner(false))
                })
            }
    }
    const handleRegister=(e)=>{
        e.preventDefault();
        RegisterFields.map(({name}) => validation(name,registerValues));
        if (Object.entries(errors).length !== 0 && errors.constructor === Object) {
            scrollToTop()
            setMessage('Wrong Inputs')
            return
        }
        else {
            dispatch(setSpinner(true))
            setMessage('')
            registerReq(registerValues)
                .then((res)=>{
                    SetToken(res.data.token);
                    const setStorage=async ()=>{
                        await localStorage.setItem('cosette_token', res.data.token)
                        await localStorage.setItem('u_type', res.data.type)
                    };
                    setStorage();
                    $('#RegisterModal').modal('hide')
                    dispatch(Login())
                    props.history.push('/dashboard')
                    window.location.reload()
                    dispatch(setSpinner(false))
                })
                .catch(err=>{
                    setMessage(err.response.data.message)
                    dispatch(setSpinner(false))
                })
            }
    }

    $('.modal').on('hide.bs.modal', function (e) {
        setErrors({})
    })



    return(
        <>
            {/*Desktop*/}
            <div className="Header container d-none d-md-flex">
                <div className="w-100">
                    <nav className="navbar navbar-expand-md navbar-dark shadow" style={{borderRadius:".5rem",paddingRight:"2%",paddingLeft:".4%",width:"100%",backgroundColor:"#f8f8f8",marginBottom:"3%",paddingTop:"2.3%",paddingBottom:"2.3%"}}>
                        {/*Links List*/}
                        <div className="collapse navbar-collapse" id="collapsibleNavbar">
                            <ul className="navbar-nav">
                                {
                                    Navbar.map(({name,href},idx)=>(
                                        name!=='Login'?
                                            <li className="nav-item" key={idx}>
                                                <a className="nav-link Header-Link" href={href}>{name}</a>
                                            </li>
                                        :
                                            !logged?
                                                <li className="nav-item" key={idx}>
                                                    <a className="nav-link mx-3 Login-Btn Header-Link" data-toggle="modal" data-target="#LoginModal" href={href}>{name}</a>
                                                </li>
                                            :
                                                <li className="nav-item" key={idx}>
                                                    <a className="nav-link Header-Link" href="/dashboard">Dashboard</a>
                                                </li>
                                    ))
                                }
                            </ul>
                        </div>
                        {/*Logo*/}
                        <div>
                            <span href="/" className="Header-Logo">PROJECT 360</span>
                        </div>
                    </nav>
                </div>
            </div>

            {/*Mobile*/}
            <div className="Header d-md-none">
               <div className="">
                   <nav className="navbar navbar-expand-md navbar-light shadow" style={{paddingRight:"3%",paddingLeft:"3%",width:"100%",backgroundColor:"#f6f6f6",marginBottom:"3%"}}>
                       {/*Navbar Collapsible Button*/}
                       <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                           <span className="navbar-toggler-icon" />
                       </button>
                       {/*Navbar Links*/}
                       <div className="collapse navbar-collapse" id="collapsibleNavbar">
                           <ul className="navbar-nav">
                               {
                                   Navbar.map(({name,href},idx)=>(
                                       name!=='Login'?
                                           <li className="nav-item" key={idx}>
                                               <a className="nav-link" href={href}>{name}</a>
                                           </li>
                                       :
                                           !logged?
                                                <React.Fragment key={idx}>
                                                    <li className="nav-item Login-Btn d-none d-md-flex">
                                                        <a className="nav-link Header-Link" data-bs-toggle="modal" data-bs-target="#LoginModal" href={'/login'}>{name}</a>
                                                    </li>
                                                    <li className="nav-item d-md-none">
                                                        <a className="nav-link" data-bs-toggle="modal" data-bs-target="#LoginModal" href={'/login'}>{name}</a>
                                                    </li>
                                                </React.Fragment>
                                               
                                           :
                                               <li className="nav-item" key={idx}>
                                                   <a className="nav-link" href="/dashboard">Dashboard</a>
                                               </li>
                                   ))
                               }
                           </ul>
                       </div>
                       {/*Logo*/}
                       <div>
                           <div className="nav-item">
                               <a href="#" className="nav-link Header-Logo d-none d-md-flex">PROJECT 360</a>
                               <a href="#" className="nav-link Header-Logo d-md-none pl-0 py-2">PROJECT 360</a>
                           </div>
                       </div>
                   </nav>
               </div>
            </div>

            {/*Modals*/}
            {
                Modals.map((modal,idx)=>(
                    <div className="modal fade" id={modal.name} key={idx}>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                {/*Modal Header*/}
                                <div className="modal-header">
                                    <h4 className="modal-title">{modal.label}</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                {/*Modal Body*/}
                                <div className="modal-body">
                                    {/*Alert*/}
                                    <ConnectionErr message={message}/>
                                    {/*Modal Form*/}
                                    {
                                        (modal.name==='LoginModal'?LoginFields:RegisterFields).map(({name,label},idx)=>(
                                            <form className="form-group d-flex flex-column mb-3" key={idx}>
                                                {/*Input Label*/}
                                                <label style={{color:"#545454"}}>
                                                    {label}
                                                </label>
                                                {/*Input*/}
                                                <input
                                                    name={name}
                                                    type={name}
                                                    value={modal.name==='LoginModal'?loginValues[name]:registerValues[name]}
                                                    className="form-control mt-1"
                                                    onChange={(e) =>
                                                        (modal.name==='LoginModal'?handleLoginChange(e):handleRegisterChange(e))
                                                    }
                                                />
                                                {/*Input Error*/}
                                                {
                                                    errors[(modal.name==='LoginModal'?name:name)]?
                                                        <div className="Input-Err-Div">
                                                            <span className="invalid-feedback d-block">
                                                                {errors[(modal.name==='LoginModal'?name:name)]}
                                                            </span>
                                                        </div>
                                                    :
                                                        null
                                                }
                                            </form>
                                        ))
                                    }
                                </div>
                                {/*Modal Footer*/}
                                <div className="modal-footer d-flex justify-content-center">
                                    <div className="d-flex align-items-center">
                                        <div className={"Login-Modal-Footer"}>
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-success"
                                                onClick={(e)=>
                                                    (modal.name==='LoginModal'?handleLogin(e):handleRegister(e))
                                                }
                                            >
                                                {modal.label}
                                            </button>
                                        </div>
                                        {
                                            modal.name==='LoginModal'?
                                                <div data-bs-dismiss="modal">
                                                    <button className="btn btn-link btn-sm text-decoration-none" data-dismiss="modal" data-toggle="modal" data-target="#RegisterModal">
                                                        Register
                                                    </button>
                                                </div>

                                            :
                                                <div data-bs-dismiss="modal">
                                                    <button className="btn btn-link btn-sm text-decoration-none" data-dismiss="modal" data-toggle="modal" data-target="#LoginModal">
                                                        Login
                                                    </button>
                                                </div>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                ))
            }
        </>
    )
}

export default withRouter(Header);
