import {Parser} from "node-sql-parser";

export const cosette_api_token= () => "0b493721c76314912b3840a667b96691"

export const emailValidate = (email) => {
    if(String(email) .toLowerCase().match( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ ))return null
    else return 'Invalid Email!'
}
export const mustFilled = (value) => (value) ? null : 'This Field Is Required'


export const scrollToTop=()=>{
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}

export const stringTrimmer=(string)=>{
    if (string) return string.replace(/\s+/g,' ').trim()
    else return null
}


export const toCosette = (query) => {
    try{
        let tajzie, parser, parsed, isObj=false;
        if (typeof query === 'object'){
            tajzie=query
            isObj=true
        }
        else{
            parser = new Parser();
            query=stringTrimmer(query)
            query = query.endsWith(';') ? query.slice(0, -1) : query
            parsed = parser.astify(query)
            tajzie = Array.isArray(parsed)? parsed[0]: parsed;
        }

        // console.log(tajzie)

        const {columns,from,type,distinct,groupby,having,_next,orderby,where,limit,union} = tajzie;
        let nested=from[0]&&from[0].expr
        let tables={},cols={},cosette_query='',table={};

        table=from[0]['table']
        tables[from[0]['table']] = from[0]['as']? from[0]['as'] : `t_${table}`
        let myTable=tables[table]

        if(Array.isArray(columns) && columns.every(col=>( col.expr.table && col.expr.column ))){
            query = query.replace(/inner join/gi,',')
            query = query.replace(/right join/gi,',')
            query = query.replace(/left join/gi,',')
            query = query.replace(/cross join/gi,',')
            query = query.replace(' on ' ,' where ')
            query = query.replace(' ON ',' where ')
            return query
        }
        else{

            cosette_query=
                'SELECT '
                +( distinct? ' distinct ' : '')
                +(
                    columns==='*'?
                        ' *'
                    :
                        columns.map((col,idx)=>(
                            col.expr.type==='column_ref'?
                                myTable + '.' + col.expr.column + ' as ' 
                                +( col.expr.as ? col.expr.as : ( 'col_' + col.expr.column ))
                            :
                            col.expr.type==='aggr_func'?
                                col.expr.args.expr.type==='star'?
                                    ' ' + col.expr.name + '(*)'
                                :
                                    ' ' + col.expr.name +'(' 
                                    +( col.expr.args.distinct? 'distinct ' : ' ' ) 
                                    + myTable + '.' + col.expr.args.expr.column + ')' + ' as '
                                    +( col.expr.args.expr.as ? col.expr.args.expr.as : ( 'col_' + col.expr.args.expr.column ))
                            :
                                ''
                        ))
                )
              
                +' from ' 
                + (
                    Array.isArray(from)? 
                        from.map((frm,i)=>
                            (
                                nested? 
                                    '(' + toCosette(from[i].expr.ast) + ')'
                                :
                                    frm.join?
                                        ( frm.as? (frm.table + ` ${frm.as}`) : (frm.table + ` t_${frm.table}`) 
                                        + (
                                            ' where '
                                            + (
                                                frm.on.left.type==='number'?
                                                    frm.on.left.value
                                                :
                                                    ( frm.on.left.table? frm.on.left.table : (!nested? myTable :'temp') ) + '.' + ( frm.on.left.column)
                                              )

                                            + ' ' + frm.on.operator + ' '

                                            + (  
                                                frm.on.right.type==='expr_list'?
                                                    frm.on.right.value[0].type==='select'? 
                                                            ( '(' + toCosette(frm.on.right.value[0]) + ')' )
                                                        :
                                                            frm.on.right.value.map( (val,idx)=> `${val.value}` )
                                                                // '(' + frm.on.right.value.map( (val,idx)=> `'${val.value}'` ) + ')'
                                                    :
                                                        frm.on.right.type==='number'?
                                                            frm.on.right.value
                                                            :
                                                            frm.on.right.type==='select'?
                                                                '(' + toCosette(frm.on.right) + ')'
                                                            :
                                                            frm.on.right.type==='single_quote_string'?
                                                                "'" + frm.on.right.value + "'"
                                                            :
                                                            frm.on.right.type==='column_ref'?
                                                                frm.on.right.as ? frm.on.right.as + '.' + frm.on.right.column : myTable + '.' + frm.on.right.column
                                                            :
                                                                frm.on.right.value[0].type==='select'? ( '(' + toCosette(frm.on.right.value[0]) + ')' ) :''
                                                                    ( Array.isArray(frm.on.right.value) && frm.on.right.value.length>1 )?
                                                                    frm.on.operator==='between'?
                                                                            // '1'
                                                                            frm.on.right.value[0]['value'] + ' and ' + frm.on.right.value[1]['value'] 
                                                                        :
                                                                            // '2'
                                                                            '(' + frm.on.right.value.map( (val,idx)=> `'${val.value}'` ) + ')'
                                                                    :
                                                                        // '3'
                                                                        isNaN(frm.on.right.value)? '"' + frm.on.right.value + '"' : frm.on.right.value
                                                                    ) 
                                        )  
                                    )
                                    :
                                        ( frm.as? (frm.table + ` ${frm.as}`) : (frm.table + ` t_${frm.table}`)  )
                                    // (`t_${frm.table} ` + frm.table) 
                                    // ( table? (table + ' ' + myTable) : (`t_${from[i].expr.ast.from[i].table} ` + from[i].expr.ast.from[i].table) )
                            )
                        )
                    :
                        ''
                  )
    
                +(
                    where?
                     ' where '
                    + (
                        where.left.type==='number'?
                            where.left.value
                        :
                            ( where.left.table? where.left.table : (!nested? myTable :'temp') ) 
                            + '.' + ( where.left.column)
                      )
                    + ' ' + where.operator + ' '
                    + (  
                        where.right.type==='expr_list'?
                            where.operator==='BETWEEN'?
                                where.right.value[0]['value'] + ' and ' + where.right.value[1]['value'] 
                            :
                            where.right.value[0].type==='select'? 
                                ( '(' + toCosette(where.right.value[0]) + ')' )
                            :
                                '(' + where.right.value.map( (val,idx)=> 
                                    val.type==='single_quote_string' ?
                                      `'${val.value}'` 
                                    :  
                                      `${val.value}` )
                                + ')'
                        :
                        where.right.type==='number'?
                            where.right.value
                        :
                        where.right.type==='select'?
                            '(' + toCosette(where.right) + ')'
                        :
                        where.right.type==='single_quote_string'?
                            "'" + where.right.value + "'"
                        :
                        where.right.type==='column_ref'?
                            where.right.as ? 
                                where.right.as + '.' + where.right.column : myTable + '.' + where.right.column
                            :
                                where.right.value[0].type==='select'? ( '(' + toCosette(where.right.value[0]) + ')' ) :''
                                    ( Array.isArray(where.right.value) && where.right.value.length>1 )?
                                        where.operator==='BETWEEN'?
                                            // '1'
                                            where.right.value[0]['value'] + ' and ' + where.right.value[1]['value'] 
                                        :
                                        where.operator==='IN'?
                                            // '2'
                                            where.right.value[0].type==='single_quote_string'?
                                                '(' + where.right.value.map( (val,idx)=> `'${val.value}'` ) + ')'
                                            :
                                                '(' + where.right.value.map( (val,idx)=> `'${val.value}'` ) + ')'
                                        :
                                            ''
                                    :
                                        // '3'
                                        isNaN(where.right.value)? '"' + where.right.value + '"' : where.right.value
                                    ) 
                        : 
                            ''
                )


                +(union? ' ' + union + ' ' + toCosette(_next) : '')
                
                
                +(
                    groupby? 
                        (' group by ' + 
                            groupby.map((grp)=>( (!nested? myTable :'temp') + '.' + [grp['column']] ))
                           
                        ) 
                    : 
                        ''
                )

                +(
                    orderby?
                        (
                         orderby.length>1?
                            orderby.map((ord,idx)=> ( (idx===0?(' order by '):' ') + (!nested? myTable :'temp') + '.' + ord['expr']['column'] + ' ' + ord['type'])  )
                        :
                            (
                                (' order by ' + (!nested? myTable :'temp') + '.' + [orderby[0]['expr']['column']] + ' ' + [orderby[0]['type']] )
                                // +(!nested? (' ' + query.substring( query.indexOf(orderby[0]['type'].toLowerCase()) ||  query.indexOf(orderby[0]['type'].toUpperCase()) ) ): '') 
                            )   
                        )
                    :
                        ''
                )        

                // +(orderby && !nested? (' ' + query.slice(query.lastIndexOf(orderby[0]['type']),query.length) ): '')

                +(limit? (' limit ' + [limit.value[0]['value']]) : '')
            ;
            // console.log('cosette', cosette_query)
            return cosette_query
        }


        
    }
    catch(err){
        console.log(err)
        return err
    } 
}

