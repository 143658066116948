import React from "react"

function CandidQueryResultsTable({data,title}){

    return(
        <div>
            {/*TABLE DATA*/}
            {
                data.length?
                    <div className="mt-2">
                        <h6>
                            {title}
                        </h6>
                        <table className="table table-bordered">
                            {/*KEYS*/}
                            <thead>
                                <tr>
                                    {Object.keys(data[0]).map((key,idx)=>(
                                        <th key={idx}>
                                            {key + ' '}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            {/*ROWS*/}
                            <tbody>
                            {
                                (data).map((row,idy)=>(
                                    <tr key={idy}>
                                        {
                                            Object.values(row).map((value,idz)=>(
                                                <td key={idz}>
                                                    {value + ' '}
                                                </td>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                :
                    <span style={{fontSize:10,color:"purple"}}></span>
            }
        </div>
    )
}
export default CandidQueryResultsTable;
