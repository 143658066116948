import React, {useEffect, useState} from "react"
import {cosette_api_token, mustFilled, stringTrimmer, toCosette} from "../js/functions";
import {createAsgReq, getTablesOfDb, getDBsList, getQuery, cosetteReq} from "../api/api";
import {setSpinner} from "../redux/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import { withRouter} from "react-router-dom";
import Table from "../components/Table";
import CandidQueryResultsTable from "../components/CandidQueryResultsTable";
import TestQueryResultsTable from "../components/TestQueryResultsTable";
import QueryFeedback from "../components/QueryFeedback";
import CreatedQuestionsList from "../components/CreatedQuestionsList";
import CreatedTablesList from "../components/CreatedTablesList";


function CreateAsgPg(props){

    const dispatch=useDispatch()
    const FieldsForSubmittingQuestion=[
        {
            name : 'can_query'
        },
        {
            name : 'question'
        }
    ]
    const FieldsForTestingCandidateQuery=[
        {
            name : 'can_query'
        },
        {
            name : 'question'
        }
    ]
    const FieldsForCompare=[
        {
            name : 'can_query'
        },
        {
            name : 'question'
        },
        {
            name : 'test_query'
        }
    ]
    const FieldsForSubmit=[
        {
            name : 'name'
        },
        {
            name : 'description'
        }
    ]
    const [values,setValues]=useState({
        type:"practice",
        grading:'immediate',
        showTables:true
    })
    const [tables, setTables] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [tableLine,setTableLine]=useState('')
    const [message,setMessage]=useState('')
    const [question,setQuestion]=useState({
        question:'',
        can_query:'',
        test_query:'',
        points:''
    })
    const [dbs, setDbs] = useState([]);
    const [Tables,SetTables]=useState([])
    const [tempRes,setTempRes]=useState({})
    const [selectedDb,setSelectedDb]=useState(null)
    const [errors, setErrors] = useState({})
    const existingDb=true;
    const [compareResults, setCompareResults] = useState([]);
    const schemas=useSelector(state=>state.utils.schemas)
    const cosette=useSelector(state=>state.utils.cosette_code)
    const [candidQueryResults, setCandidQueryResults] = useState([]);
    const [update,setUpdate]=useState(0)

    const validation = (name,fields) => {
        let hasError
        let newErrors = errors
        if (name==='name' || name==='description'){
            hasError = mustFilled(values[name]?values[name].trim():values[name])
        }
        else{
            hasError = mustFilled(question[name]?question[name].trim():question[name])
        }
        if (hasError)
            newErrors[name] = hasError
        else {
            delete newErrors[name];
        }
        setErrors(Object.assign({}, newErrors))
    }
    const deleteExtraErrors=(fields)=>{
        const notWanted = Object.keys(errors).filter(item => !fields.includes(item))
        let newErrors = errors
        notWanted.map(i => delete newErrors[i])
        if (values.type!=='test') delete newErrors['points']
        setErrors(Object.assign({}, newErrors))
    }



    const handleChange=(e)=>{
        const {name,value,checked}=e.target;
        if (name==='existingDb'){

        }
        else if (name==='showTables'){
            setValues({
                ...values,
                [name]:checked
            })
        }
        else{
            setValues({
                ...values,
                [name]:value
            })
        }
    }
    const handleTableLineChange=(e)=>{
        const {name,value}=e.target;
        setTableLine(value)
    }
    const handleQuestionLineChange=(e)=>{
        const {name,value}=e.target;
        setQuestion({
            ...question,
            [name]: value
        })
    }
    // For adding names and column separately
    const handleAddTable=(e)=>{
        e.preventDefault();
        let tableCode,i,schema,tableName;
        tableCode=stringTrimmer(tableLine)
        tableCode=tableCode.slice(12,tableCode.length)
        i=tableCode.indexOf('(')
        schema=stringTrimmer(tableCode.slice(i,tableCode.length).replace(/\s/g, ''))
        tableName=tableCode.slice(0,i)
        setTables([
            ...tables,
            {
                schema:schema,
                name:tableName
            }
        ])
        setTableLine('')

    }

    const handleSubmit=(e)=>{
        e.preventDefault();
        deleteExtraErrors(FieldsForSubmit)
        FieldsForSubmit.map(({name})=>validation(name,FieldsForSubmit))
        if (Object.entries(errors).length !== 0 && errors.constructor === Object) {
            alert('Assignment is not complete. Make sure required fields are filled and questions are submitted.')
            return
        }
        else{
            if (!questions.length){
                alert('WARNING: No Questions Are Submitted!')
                return
            }
            else{
                let maxGrade = 0;
                questions.forEach((q)=>{
                    return maxGrade+=(+(q.points))
                })
                let body={
                    name:values.name,
                    description:values.description,
                    tables:tables,
                    questions:questions,
                    type:values.type,
                    grading:values.grading,
                    db:selectedDb,
                    maxGrade:maxGrade,
                    showTables:values.showTables
                }

                dispatch(setSpinner(true))
                createAsgReq(body)
                    .then(res=>{
                        dispatch(setSpinner(false))
                        props.history.push('/asgs')
                    })
                    .catch(err=>{
                        if (err.response){
                        }
                        dispatch(setSpinner(false))
                    })
            }
        }
    }

    const handleSelectDb=(e)=>{
        e.preventDefault();
        const {value}=e.target;
        let selected=dbs.find((item)=>item.Database===value)
        setSelectedDb(selected.Database)
    }

    const handleRunTest=(e)=>{
        e.preventDefault()
        setMessage('')
        deleteExtraErrors(FieldsForTestingCandidateQuery)
        FieldsForTestingCandidateQuery.map(({name})=> validation(name,FieldsForTestingCandidateQuery))
        if (Object.entries(errors).length !== 0 && errors.constructor === Object) {
            return
        }
        else{
            dispatch(setSpinner(true))
            getQuery(selectedDb,question.can_query)
                .then(res=>{
                    setCandidQueryResults(res.data)
                    if(!res.data.length) alert('This query did not return any results')
                    dispatch(setSpinner(false))
                })
                .catch(err=>{
                    dispatch(setSpinner(false))
                    setCandidQueryResults([])
                    if (err.response){
                        setMessage(err.response.data.sqlMessage)
                    }
                    else{
                        setMessage('Connection Error')
                    }
                })
        }
    }

    // TURN OFF SPINNER
    useEffect(() => {
        setTimeout(()=>{
            dispatch(setSpinner(false))
        },1000)
    }, []);


    // GET EXISTING DATABASES ON STARTUP
    useEffect(()=>{
        dispatch(setSpinner(true))
        getDBsList()
            .then(res=>{
                setDbs(res.data.databases)
                setSelectedDb(res.data.databases[0]['Database'])
                dispatch(setSpinner(false))
            })
            .catch(err=>{
                dispatch(setSpinner(false))
            })
    },[])

    // GET TABLES LIST WHEN DBs ARE RECEIVED AND ONE IS SELECTED
    useEffect(()=>{
        if(selectedDb){
            dispatch(setSpinner(true))
            getTablesOfDb(selectedDb)
                .then(res=>{
                    SetTables(res.data.tables)
                    setUpdate(update=>update+1)
                    dispatch(setSpinner(false))
                })
                .catch(err=>{
                    dispatch(setSpinner(false))
                })  
        }
    },[selectedDb])

    // DELETE QUESTIONS WHEN MODE IS Test, BECAUSE THE QUESTIONS SUBMITTED IN 
    // PRACTICE MODE DON'T HAVE GRADES
    useEffect(()=>{
        if(values.type==='test'){
            setQuestions([])
        }
    },[values.type])


    const handleRunCompare=(e)=>{
        e.preventDefault();
        deleteExtraErrors(FieldsForCompare)
        FieldsForCompare.map(({name})=>validation(name,FieldsForCompare))
        if (Object.entries(errors).length !== 0 && errors.constructor === Object) {
            return
        }
        else{
            dispatch(setSpinner(true))
            let TempResults = {}
            // SEND COSETTE PROGRAM TO API
            let cos_code=cosette
                +" query q1 "
                +"`"
                +toCosette(question.can_query)
                +"`; "
                +" query q2 "
                +"`"
                +toCosette(question.test_query)
                +"`; "
                +" verify q1 q2;"
            ;
            let body=new FormData();
            body.append('api_key',cosette_api_token())
            body.append('query',cos_code)

            cosetteReq(body)
                .then(res=>{
                    if(res.data.result==='ERROR'){
                        setCandidQueryResults([])
                        setCompareResults(res.data.error_msg.slice(0,150) + '...')
                    }
                    else setCompareResults(res.data.result)
                    if (res.data.result==='NEQ'){
                        let queryPromises=[]
                        let queryResponsesObj=tempRes?tempRes:{}

                        queryPromises.push(
                            getQuery(selectedDb,question.can_query)
                                .then(res=>{
                                    queryResponsesObj={
                                        ...queryResponsesObj,
                                        [question.question] : {...queryResponsesObj[question.question],can:res.data}
                                    }
                                })
                                .catch(err=>{
                                    setCandidQueryResults([])
                                })
                        )
                        queryPromises.push(
                            getQuery(selectedDb,question.test_query)
                                .then(res=>{
                                    queryResponsesObj={
                                        ...queryResponsesObj,
                                        [question.question] : {...queryResponsesObj[question.question],test:res.data}
                                    }
                                })
                                .catch(err=>{
                                    setCandidQueryResults([])
                                })
                        )
                        Promise.all(queryPromises)
                            .then(() => {
                                let objKeys =Object.keys(queryResponsesObj).map((i)=>i)
                                let objVals =Object.values(queryResponsesObj).map((i)=>i)
                                let temp={
                                    ...TempResults
                                }
                                objKeys.map((key,idx)=>(
                                    temp[key]=objVals[idx]
                                ))
                                TempResults=temp
                                setTempRes(temp)
                            })
                            .catch(err=>{
                                setCandidQueryResults([])
                            })
                    }
                    else{
                        let objj={}
                        objj=TempResults
                        delete objj[question]
                        setTempRes(objj)
                    }
                    dispatch(setSpinner(false))
                })
                .catch(err=>{
                    dispatch(setSpinner(false))
                    setCandidQueryResults([])
                })
                .finally(()=>{
                    dispatch(setSpinner(false))
                })
        }
        // Starts processing the queries and submits to Cosette
        // CREATE A COSETTE BASED ON THEIR CANDIDATE
        // QUERY AND THE QUERY THAT STUDENT HAS
        // ENTERED AND SUBMIT THEM TO COSETTE, FINALLY
        // SAVES THE RESULT FOR EACH QUESTION IN THE "results" STATE
    }

  
    let counter=1;
    const handleAddQuestions=(e)=>{
        e.preventDefault();
        deleteExtraErrors(FieldsForSubmittingQuestion)
        if (values.type==='test') {
            validation('points')
        }
        FieldsForSubmittingQuestion.map(({name})=>validation(name,FieldsForSubmittingQuestion))
        if (Object.entries(errors).length !== 0 && errors.constructor === Object) {
            return
        }
        else{
            setQuestions([
                ...questions,
                {
                    question:question.question,
                    can_query:stringTrimmer(question.can_query).endsWith(';')? stringTrimmer(question.can_query).slice(0, -1):stringTrimmer(question.can_query),
                    cosette_query:'Sample Cosette Translation',
                    points:question.points
                }
            ])
            setQuestion({
                question:'',
                can_query:'',
                test_query:'',
                cosette_query:'',
                points:''
            })
        }
        
        setTempRes({})
        setCompareResults([])
        setCandidQueryResults([])
    }



    return(
        <div className="py-3">

            {/*Page Title*/}
            <h3 className="Green">
                Create Assignment
            </h3>

            {/*Assignment Name*/}
            <div className="form-group my-3">
                {/*Title*/}
                <label>
                    Enter the name for the assignment
                </label>
                {/*Assignment Input*/}
                <input type="text" name={'name'} onChange={(e)=>handleChange(e)} value={values['name']} className="form-control form-control-sm" placeholder={'Assignment Name'}/>
                {/*Input Error*/}
                {
                    errors['name']?
                        <div className="Input-Err-Div">
                            <span className="invalid-feedback d-block">
                                {errors['name']}
                            </span>
                        </div>
                    :
                        null
                }
            </div>

            {/*Type*/}
            <div className="form-group my-3">
                {/*Type Title*/}
                <label>
                    Enter assignment's type:
                </label>
                {/*Type Input*/}
                <div className="d-flex align-items-center">
                    {/*Practice*/}
                    <div className="d-flex align-items-center">
                        <label style={{fontFamily:'Nunito-Bold'}} className="mb-0" htmlFor="type_p">Practice:</label>
                        <input id='type_p' name={'type'} value={'practice'} checked={values.type['practice']} type="radio" onChange={(e)=>handleChange(e)} className="custom-radio mx-1" defaultChecked="true" />
                    </div>
                    {/*Test*/}
                    <div className="d-flex align-items-center mx-2">
                        <label style={{fontFamily:'Nunito-Bold'}} className="mb-0" htmlFor="type_q">Test:</label>
                        <input id='type_q' name={'type'} value={'test'} checked={values.type['test']} type="radio" onChange={(e)=>handleChange(e)} className="custom-radio mx-1" />
                    </div>
                </div>
            </div>

            {/*Show Tables To Student*/}
            <div className="form-group my-3">
                {/*Existing Title*/}
                <label>
                    Show the tables to the student?
                </label>
                {/*Existing Input Div*/}
                <input name={'showTables'} checked={values.showTables} type="checkbox" onChange={(e)=>handleChange(e)} className="custom-radio mx-2" />
            </div>

            {/*See Results*/}
            {
                values.type==='test'?
                    <div className="form-group my-3">
                        {/*Type Title*/}
                        <label>
                            Grading method:
                        </label>
                        {/*Type Input*/}
                        <div className="d-flex align-items-center">
                            {/*Practice*/}
                            <div className="d-flex align-items-center">
                                <label style={{fontFamily:'Nunito-Bold'}} className="mb-0" htmlFor="immediate">Immediate: </label>
                                <input id='immediate' name={'grading'} value={'immediate'} defaultChecked={values.type['immediate']} checked={values.type['immediate']} type="radio" onChange={(e)=>handleChange(e)} className="custom-radio mx-1" />
                            </div>
                            {/*Test*/}
                            <div className="d-flex align-items-center mx-2">
                                <label style={{fontFamily:'Nunito-Bold'}} className="mb-0" htmlFor="deferred">deferred: </label>
                                <input id='deferred' name={'grading'} defaultChecked={values.type['deferred']} value={'deferred'} checked={values.type['deferred']} type="radio" onChange={(e)=>handleChange(e)} className="custom-radio mx-1" />
                            </div>
                        </div>
                    </div>
                :
                    null
            }

            {/*Description*/}
            <div className="form-group my-3">
                {/*Desc Title*/}
                <label>
                    Enter the description for the assignment:
                </label>
                {/*Description Input*/}
                <textarea rows={5} name={'description'} onChange={(e)=>handleChange(e)} className="form-control" value={values.description} placeholder={'Description'} />
                {/*Input Error*/}
                {
                    errors['description']?
                        <div className="Input-Err-Div">
                            <span className="invalid-feedback d-block">
                                {errors['description']}
                            </span>
                        </div>
                    :
                        null
                }
            </div>

            {/*Select either between existing databases or create a new database*/}
            {
                !existingDb?
                    <div className="my-3 form-group">
                        {/*Table Title*/}
                        <label>
                            Enter the code for creating your table:
                        </label>
                        {/*Table Name*/}
                        <input name={'table_line'} onChange={(e)=>handleTableLineChange(e)} className="form-control" value={tableLine} placeholder={'e.g. CREATE TABLE t1(a : Int, b : Int);'} />
                        {/*Add Button*/}
                        <button onClick={(e)=>handleAddTable(e)} style={{fontSize:11}} className="mt-2 btn btn-sm btn-primary">Add Table</button>
                        <button onClick={(e)=>handleAddTable(e)} style={{fontSize:11}} className="mt-2 btn btn-sm btn-primary">Add Table</button>
                    </div>
                :
                    <>
                        <div className="my-3 form-group">
                            <label htmlFor="">Select your desired Database:</label>
                            <select name="" value={selectedDb} onChange={(e)=>handleSelectDb(e)} className="form-control form-control-sm">
                                {
                                    dbs.map(({Database},idx)=>(
                                        <option className="" style={{fontFamily:'Nunito-Bold'}} key={idx}>
                                            {Database}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                        {
                            selectedDb?
                                <div style={{fontFamily:"Nunito-Bold",backgroundColor:"red"}}>

                                </div>
                            :
                                null
                        }
                    </>
            }

            {/*TABLES OF THE SELECTED DATABASE*/}
            {
                Tables.map((name,idx)=>(
                    <Table showTables={true} update={update} id={selectedDb} name={name} {...props} key={idx} />
                ))
            }

            {/*Created Tables*/}
            <CreatedTablesList tables={tables} />


            {
                existingDb?
                    selectedDb?
                        <>
                            {/*Questions*/}
                            <div className="form-group mt-4">
                                {/*Question Label*/}
                                <label>
                                    Enter the question:
                                </label>
                                {/*Question Input*/}
                                <input name='question' value={question.question} onChange={(e)=>handleQuestionLineChange(e)} placeholder='List cats (PID, Name) living in Moscow, Idaho.' className="form-control form-control-sm" type="text"/>
                                {/*Input Error*/}
                                {
                                    errors['question']?
                                        <div className="Input-Err-Div">
                                            <span className="invalid-feedback d-block">
                                                {errors['question']}
                                            </span>
                                        </div>
                                    :
                                        null
                                }
                            </div>

                            {/* Candidate Query*/}
                            <div className="form-group mt-1 mb-2">
                                {/*Query Label*/}
                                <label>
                                    Enter the candidate query for the question:
                                </label>
                                {/*Query Input*/}
                                <textarea name='can_query' value={question.can_query} className="form-control form-control-sm" onChange={(e)=>handleQuestionLineChange(e)} placeholder='SELECT * FROM Pets;'/>
                                {/*Input Error*/}
                                {
                                    errors['can_query']?
                                        <div className="Input-Err-Div mb-1">
                                            <span className="invalid-feedback d-block">
                                                {errors['can_query']}
                                            </span>
                                        </div>
                                    :
                                        null
                                }
                                {/* SQL ERROR */}
                                {
                                    message?
                                        <div className="Input-Err-Div">
                                            <span className="invalid-feedback d-block">
                                                {message}
                                            </span>
                                        </div>
                                    :
                                        null
                                }
                                {/*Run Button*/}
                                <button onClick={(e)=>handleRunTest(e)} style={{fontSize:11}} className="mt-2 btn btn-sm btn-secondary">Run Query</button>
                            </div>

                            {/* Test Query*/}
                            <div className="form-group">
                                {/*Query Label*/}
                                <label>
                                    Enter your test query:
                                </label>
                                {/*Query Input*/}
                                <textarea name='test_query' value={question.test_query} className="form-control form-control-sm" onChange={(e)=>handleQuestionLineChange(e)} placeholder='SELECT * FROM Pets;'/>
                                {/*Input Error*/}
                                {
                                    errors['test_query']?
                                        <div className="Input-Err-Div">
                                            <span className="invalid-feedback d-block">
                                                {errors['test_query']}
                                            </span>
                                        </div>
                                    :
                                        <>
                                            {/*Compare Result Feedback*/}
                                            <QueryFeedback eq={true} data={compareResults} />
                                        </>
                                }
                            </div>

                            {/*Question Points (Grade)*/}
                            {
                                values.type==='test'?
                                    <>
                                        <div className="form-group">
                                            {/*Query Label*/}
                                            <label>
                                                Enter the points for this question:
                                            </label>
                                            {/*Query Input*/}
                                            <input name='points' value={question.points} className="form-control form-control-sm" onChange={(e)=>handleQuestionLineChange(e)} placeholder='6'/>
                                            {/*Input Error*/}
                                            {
                                                errors['points']?
                                                    <div className="Input-Err-Div">
                                                        <span className="invalid-feedback d-block">
                                                            {errors['points']}
                                                        </span>
                                                    </div>
                                                :
                                                    null
                                            }
                                        </div>
                                    </>
                                :
                                    null
                            }


                            {/*Submit Question & Compare Button*/}
                            <div className="d-flex">
                                {/*Submit Button*/}
                                <button onClick={(e)=>handleAddQuestions(e)} style={{fontSize:11}} className="mb-3 btn btn-sm btn-primary">Submit Question</button>
                                {/*Test Button*/}
                                <button onClick={(e)=>handleRunCompare(e)} style={{fontSize:11}} className="mb-3 mx-1 btn btn-sm btn-secondary">Compare</button>
                            </div>

                            {/*Candid Query Results*/}
                            <CandidQueryResultsTable data={candidQueryResults} title='Candidate Query Returns:' />

                            {/*Test Query Results*/}
                            <TestQueryResultsTable data={tempRes[question.question]} title='Test Query Returns:' />
                        </>
                    :
                        null
                :
                    null
            }

            <br/>

            {/*Created Questions*/}
            <CreatedQuestionsList questions={questions} />

            {/*Submit Question*/}
            <button onClick={(e)=>handleSubmit(e)} className="mb-3 btn btn-sm btn-success">Submit Assignment</button>

        </div>
    )
}
export default withRouter(CreateAsgPg);
