import React from "react"

function YourQueryResults({data,title}) {
    

    return(
        <>
            {
               data && data['std'].length?
                    <>
                        <h6>{title}</h6>
                        {
                            data?
                            // DRAW TABLE
                            <div className="mt-2">
                                {/*TABLE*/}
                                <table className="table table-bordered">
                                    {/*KEYS*/}
                                    <thead>
                                    <tr>
                                        {
                                            Object.keys(data['std'][0]).map((key,idx)=>(
                                                <th key={idx}>
                                                    {key + ' '}
                                                </th>
                                            ))
                                        }
                                    </tr>
                                    </thead>
                                    {/*ROWS*/}
                                    <tbody>
                                    {
                                        (data['std']).map((row,idy)=>(
                                            <tr key={idy}>
                                                {
                                                    Object.values(row).map((value,idz)=>(
                                                        <td key={idz}>
                                                            {value + ' '}
                                                        </td>
                                                    ))
                                                }
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                        :
                            null
                        }
                    </>
                :
                    <p className="text-danger">No valid result for your entered query</p>
            }
        </>
    )
}

export default YourQueryResults
