import React from "react"

function CorrectQueryResults({data,title}) {

    return(
        <>
            <h6>{title}</h6>
            {
                data?
                    // DRAW TABLE
                    <div className="mt-2">
                        {/*TABLE*/}
                        <table className="table table-bordered">
                            {/*KEYS*/}
                            <thead>
                            <tr>
                                {
                                    Object.keys(data['ins'][0]).map((key,idx)=>(
                                        <th key={idx}>
                                            {key + ' '}
                                        </th>
                                    ))
                                }
                            </tr>
                            </thead>
                            {/*ROWS*/}
                            <tbody>
                            {
                                (data['ins']).map((row,idy)=>(
                                    <tr key={idy}>
                                        {
                                            Object.values(row).map((value,idz)=>(
                                                <td key={idz}>
                                                    {value + ' '}
                                                </td>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                :
                    null
            }
        </>
    )
}

export default CorrectQueryResults
