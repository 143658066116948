import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {getSubmissionsOfTheStudent,getSubmissionList} from "../api/api";
import {setSpinner} from "../redux/actions/actions";
import ConnectionErr from "../components/Connection_Err";


function SubmittedList() {

    const dispatch=useDispatch()
    const [list, setList] = useState([]);
    const [submissionList,setSubmissionList]=useState([])
    const [message, setMessage] = useState('');
    const type=useSelector(state=>state.utils.userType)
    const student=type==='115'

    // GET SUBMITTED LIST
    useEffect(()=>{
        dispatch(setSpinner(true))
        getSubmissionsOfTheStudent()
            .then(res=>{
                setList(res.data)
                dispatch(setSpinner(false))
            })
            .catch(err=>{
                dispatch(setSpinner(false))
            })
    },[])

    // GET ASSIGNMENTS LIST
    useEffect(()=>{
        dispatch(setSpinner(true))
        getSubmissionList()
            .then(res=>{
                setSubmissionList(res.data)
                dispatch(setSpinner(false))
            })
            .catch(err=>{
                dispatch(setSpinner(false))
            })
    },[])




    return(
        <div>
            {/*Error*/}
            <ConnectionErr message={message} />
            {/*Title*/}
            <h4>Select your desired assignment</h4>
            <hr/>
            {/*Items*/}
            <div className="container-fluid">
                <div className="mt-2 py-3 row d-flex flex-wrap align-items-start">
                    {
                        student?
                            list.length?
                                list.map((sub_item,idx)=>(
                                    <Link to={student?`/asgs/${sub_item.asg_id}`:`/asgs/${sub_item.asg_id}/${sub_item._id}`} style={{borderRadius:'.5rem',height:130}} className="Card-Lift mx-md-2 mx-0 d-flex flex-column mb-3 col-sm-6 col-md-4 col-lg-4 col-xl-3 align-items-center justify-content-center border shadow" key={idx}>
                                      <span style={{fontFamily:'Nunito-Bold',cursor:'default',fontSize:13.5}} className="text-white text-center">{sub_item.asg_name}</span>
                                    </Link>
                                ))
                            :
                                <h6 className="text-danger">No submitted assignments found</h6>
                        :
                            submissionList.length?
                                submissionList.map((assignment,idx)=>(
                                    !assignment.archived?
                                        <Link to={`/submitted-asgs/${assignment._id}`} style={{borderRadius:'.5rem',height:130,backgroundColor:`${assignment.type==='practice'?'#2ec8d5':'#2ed58f'}`}} className="Card-Lift mx-md-2 mx-0 d-flex flex-column mb-3 col-sm-6 col-md-4 col-lg-4 col-xl-3 align-items-center justify-content-center border shadow" key={idx}>
                                            <span style={{fontFamily:'Nunito-Bold',cursor:'default',fontSize:15}} className="text-white text-center">{assignment.name}</span>
                                            <br/>
                                            <span style={{fontFamily:'Nunito-Bold',cursor:'default',fontSize:13}} className="text-white text-center">{assignment.grading}</span>
                                        </Link>
                                    :
                                        null
                                ))
                            :
                                <h6 className="text-danger">No submitted assignments found</h6>
                    }
                </div>
            </div>
        </div>

    )
}

export default SubmittedList;
