import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

// When a component is PrivateRoute, means it will be rendered
// only if the user is logged in, otherwise they would be redirected to login pages
// For example: Dashboard.

const mapStateToProps = state => {
    return {
        type: state.utils.userType
    }
}


function onlyInstructor({component: Component, ...props}) {
    
    const { type } = props

    return (
        <Route
            render={(props) => (
                type?
                    type!=='115' ?
                        <Component {...props}/>
                    : 
                        <Redirect
                            to={{
                                pathname: '/asgs',
                                state: { from: props.location }
                            }}
                        />
                :
                    null
            )}
            {...props}
        />
    )
}

export default connect(mapStateToProps)(onlyInstructor)
