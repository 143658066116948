import React from "react"
import {Logout} from "../redux/actions/actions";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";





function ManageDBs() {

    const Fields=[
        {
            name:'Create Database',
            url:"create-db"
        },
        {
            name:'Existing Databases',
            url:"dbs-list"
        }
    ];
    const dispatch = useDispatch()

    const handleClick=(e,name)=>{
        if (name==='Logout'){
            e.preventDefault();
            dispatch(Logout())
        }
    }


    return(
        <div style={{paddingBottom:"2.5%"}}>

            <div className="container-fluid">
                <div className="mt-2 py-3 row d-flex flex-wrap align-items-start">
                    {
                        (Fields).map((item,idx)=>(
                            <Link to={item.url} onClick={(e)=>handleClick(e,item.name)} className="Card-Lift mx-md-2 mx-0 d-flex mb-3 col-sm-6 col-md-4 col-lg-4 col-xl-3 align-items-center justify-content-center border shadow" style={{borderRadius:'.5rem',height:130}} key={idx}>
                                <span style={{fontFamily:'Nunito-Bold'}} className="text-white text-center">{item.name}</span>
                            </Link>
                        ))
                    }
                </div>
            </div>

        </div>
    )
}

export default ManageDBs;
