import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import {connect} from 'react-redux'

// When a component is isAuthenticated, means it won't be rendered if the user is logged in
// For example: PhoneNumber(login), Authorize, etc.

const mapStateToProps = state => {
    return {
        Log: state.log
    }
}

function IsAuthenticated({component: Component, ...props}) {
    const { Log } = props
    return (
        <Route
            render={(props) => (
                !Log ?
                    <Component {...props}/>
                    : <Redirect
                        to={{
                            pathname: '/dashboard',
                            state: { from: props.location }
                        }}
                    />
            )}
            {...props}
        />
    )
}

export default connect(mapStateToProps)(IsAuthenticated)
