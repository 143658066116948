import React,{useEffect,useState} from "react"
import { useDispatch,useSelector } from "react-redux"
import {useParams} from "react-router-dom"
import {getReportsForAsg} from "../api/api";
import {setSpinner} from "../redux/actions/actions"
import ConnectionErr from "../components/Connection_Err";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

function ReportForThisAsg(props){

    const {id}=useParams()
    const dispatch=useDispatch()
    const [message, setMessage] = useState('');
    const [list, setList] = useState([]);
    const type=useSelector(state=>state.utils.userType)

    // GET SUBMITTED LIST
    useEffect(()=>{
        dispatch(setSpinner(true))
        getReportsForAsg(id)
            .then(res=>{
                setList(res.data)
                dispatch(setSpinner(false))
            })
            .catch(err=>{
                dispatch(setSpinner(false))
            })
    },[])

    return(
        <div>
            {/*Error*/}
            <ConnectionErr message={message} />
            {/*Title*/}
            <div className="d-flex justify-content-between">
                <h4>Report for {list.length? list[0].asg_name + ':' : ' this assignment: '}</h4>
                <a href="#" onClick={(e)=>window.print()}>
                   <FontAwesomeIcon color='primary' icon={faPrint} style={{fontSize:18}} /> 
                </a>
            </div>
            
            <hr/>
            {/*Items*/}
            {
                list.length?
                    <div className="container-fluid">
                        <div className="mt-2 py-3 row d-flex flex-wrap align-items-start">
                            <table className="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Student Name</th>
                                        <th>StudentID</th>
                                        <th>Grade</th>
                                        <th>Max Possible</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        list.map((sub_item,idx)=>(
                                            <tr key={idx}>
                                                <td>{sub_item.std_name}</td>
                                                <td>{sub_item.std_id}</td>
                                                <td>{sub_item.totalGrade}</td>
                                                <td>{sub_item.maxGrade}</td>
                                            </tr>
                                        ))
                                        
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                :
                    <h6 className="text-danger">No reports were found for this assignment</h6>
            }
        </div>
    )
}
export default ReportForThisAsg