import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

// When a component is PrivateRoute, means it will be rendered
// only if the user is logged in, otherwise they would be redirected to login pages
// For example: Dashboard.

const mapStateToProps = state => {
    return {
        Log: state.log
    }
}


function PrivateRoute({component: Component, ...props}) {
    const { Log } = props
    return (
        <Route
            render={(props) => (
                Log ?
                    <Component {...props}/>
                    : <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location }
                        }}
                    />
            )}
            {...props}
        />
    )
}

export default connect(mapStateToProps)(PrivateRoute)
